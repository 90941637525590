import React, { useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { Button } from "react-bootstrap";
import {
  millerApi,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../api/miller.api";
import DataTable from "react-data-table-component";
import { Loader } from "../../Components/Loader";

export const UserListContainer = () => {
  const dispatch = useDispatch();
  const [exportUsers] = millerApi.endpoints.exportUsers.useLazyQuery();

  const [updateUser, { isLoading: isUserDataLoading }] =
    useUpdateUserMutation();

  const [state, setState] = useState({
    search: "",
    skip: 0,
    take: 20,
  });
  const { isLoading, data } = useGetUsersQuery({
    search: state.search,
    skip: state.skip,
    take: state.take,
  });

  const handleUpdate = useCallback(
    async (body = {}) => {
      if (window.confirm("Are you sure?")) {
        await updateUser(body);
        dispatch(millerApi.util.invalidateTags(["Users"]));
      }
    },
    [dispatch, updateUser]
  );

  // Transform data

  const columns = [
    {
      name: "Point (By Promo)",
      selector: (row) => row.point,
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "Personal ID",
      selector: (row) => row.personalId,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "approved" ? (
          <span style={{ color: "#00a558", fontWeight: "bold" }}>
            {row.status === "approved" ? "Verified" : ""}
          </span>
        ) : row.status === "on_hold" ? (
          <span style={{ color: "orange", fontWeight: "bold" }}>
            {row.status === "on_hold" ? "On hold" : ""}
          </span>
        ) : row.status === "rejected" ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            {row.status === "rejected" ? "Rejected" : ""}
          </span>
        ) : null,

      sortable: true,
    },
    {
      name: "Email Verified At",
      selector: (row) => row.emailVerifiedAt,
      sortable: true,
    },

    {
      name: "actions",
      selector: (row) => (
        <>
          {row.status !== "rejected" ? (
            <Button
              className="block-user-btn"
              style={{
                border: "0",
                fontSize: "14px",
                margin: "0 5px",
              }}
              onClick={() => handleUpdate({ id: row.id, status: "rejected" })}
              disabled={isUserDataLoading}
            >
              Reject
            </Button>
          ) : null}
          {row.status !== "on_hold" ? (
            <Button
              className="hold-btn"
              style={{
                border: "0",
                fontSize: "14px",
                margin: "0 5px",
              }}
              onClick={() => handleUpdate({ id: row.id, status: "on_hold" })}
              disabled={isUserDataLoading}
            >
              Hold
            </Button>
          ) : null}

          {row.status !== "approved" ? (
            <Button
              className="approve-btn"
              style={{
                border: "0",
                fontSize: "12px",
                margin: "0 5px",
              }}
              onClick={() => handleUpdate({ id: row.id, status: "approved" })}
              disabled={isUserDataLoading}
            >
              Approve
            </Button>
          ) : null}
        </>
      ),
      sortable: false,
    },
  ];

  const loadItems = useCallback(
    async (perPage) => {
      if (isLoading) return;
      setState((prevState) => ({
        ...prevState,
        skip: (perPage - 1) * prevState.take,
        // skip:
        //   perPage > 1
        //     ? prevState.skip + prevState.take
        //     : prevState.skip - prevState.take,
      }));
      dispatch(millerApi.util.invalidateTags(["User"]));
    },
    [isLoading, dispatch]
  );

  // Transform data

  const handleChangeSearch = useDebouncedCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, 1000);

  const tHead = useMemo(() => {
    return (
      <>
        <p
          style={{
            fontSize: "20px",
            marginBottom: "15px",
            marginRight: "20px",
          }}
        >
          Total: {data?.total}
        </p>
        <p
          style={{
            fontSize: "20px",
            marginBottom: "15px",
            marginRight: "20px",
          }}
        >
          Registered By Promo: {data?.registeredByPromo}
        </p>
        <button
          className="export-btn"
          onClick={() => {
            exportUsers();
          }}
        >
          Export .csv
        </button>
        <div className="filter-side">
          <input
            placeholder="For search name, lastname, id, email..."
            onChange={handleChangeSearch}
            name="search"
            // value={state.search}
          />
        </div>
      </>
    );
  }, [handleChangeSearch, data, exportUsers]);

  return (
    <div className="card-block all-users-list">
      <DataTable
        title="All Users"
        subHeader
        subHeaderComponent={tHead}
        progressPending={isLoading}
        progressComponent={<Loader />}
        columns={columns}
        data={data && data.data}
        //
        pagination
        paginationServer
        paginationTotalRows={
          state.skip + state.take + (data && data.data.length < 20 ? 0 : 1)
        }
        paginationPerPage={20}
        paginationComponentOptions={{
          noRowsPerPage: false,
          selectAllRowsItem: false,
          rowsPerPageText: "",
        }}
        paginationIconFirstPage={null}
        paginationIconLastPage={null}
        onChangePage={loadItems}
      />
      {isLoading ? <h3 className="loading-item">Loading...</h3> : null}
    </div>
  );
};
