import { useNavigate } from "react-router";
import { useGetMeQuery } from "../api/miller.api";

export function PrivateRoute({ children, rollBackUrl = "/signin" }) {
  const navigate = useNavigate();
  const { isLoading, data, isError } = useGetMeQuery();

  if (isLoading) {
    return (
      <div className="loading-wrapper">
        <h1>Please wait loading...</h1>
      </div>
    );
  }

  return data && !isError ? <>{children}</> : navigate(rollBackUrl);
}
