import React from "react";

import jwt_decode from "jwt-decode";

import { Header } from "../Components/dashboard/header";
import { UserListContainer as DashboardUserListContainer } from "./user/UserListContainer";
import { ChequeContainer as ModeratorChequeList } from "./moderator/cheque/ChequeContainer";
import { UsersContainer as PromoUserContainer } from "./promo/users/UsersContainer";

const Dashboard = () => {
  const user = jwt_decode(localStorage.getItem("access_token"));

  return (
    <>
      <div className="dashboard-wrapper">
        <Header />
        <div className="dashboard-content">
          {/**
           * create user Component
           */}
          {user && ["promo_agent"].includes(user.role) ? (
            <PromoUserContainer />
          ) : null}

          {/**
           * moderator cheque
           */}
          {user && ["moderator"].includes(user.role) ? (
            <ModeratorChequeList />
          ) : null}
          {/**
           * Admin Cheque List
           */}
          {user && ["admin"].includes(user.role) ? (
            <DashboardUserListContainer />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Dashboard;
