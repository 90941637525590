import { useFormik } from "formik";
import { SignUpValidation } from "./validation";
import { useSignUpMutation } from "../../api/miller.api";
import { Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const SignUpModal = ({ visible, setVisible, setVisibleSignIn }) => {
  const navigate = useNavigate();

  const [fetchSignUp, { isLoading, error, isSuccess }] = useSignUpMutation();
  const [mailProvider, setMailProvider] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      agree: false,
      personalId: "",
      //
    },
    // validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: SignUpValidation,
    onSubmit: async (values, methods) => {
      setMailProvider(formik?.values?.email);
      try {
        await fetchSignUp(values);

        // setVisible(false);
        methods.resetForm();
      } catch (error) {}
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate("/thank");
    }
  }, [isSuccess]);
  console.log(formik.values);
  console.log("errors", formik.errors);
  return visible ? (
    <div className="modal-item-wrapper">
      <div className="modal-item-content">
        <div className="logo">
        <img src="/images/logomain.svg" />
        </div>
        <div className="close-modal" onClick={() => setVisible(false)}>
          <svg width="57" height="58" viewBox="0 0 57 58" fill="none">
            <g filter="url(#filter0_d_57_2)">
              <rect
                x="53"
                y="50"
                width="49"
                height="50"
                rx="16"
                transform="rotate(180 53 50)"
                fill="#D0B478"
              />
            </g>
            <path
              d="M22 17L37 32M37 17L22 32"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
            />
            <defs>
              <filter
                id="filter0_d_57_2"
                x="0"
                y="0"
                width="57"
                height="58"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_57_2"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_57_2"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="form-item">
          <h3>რეგისტრაცია</h3>

          {error && error.data && error.data.message && (
            <Alert variant="danger">
              {error.data.error === "Conflict"
                ? "მითითებული მონაცემებით მომხმარებელი უკვე რეგისტრირებულია."
                : error.data.message === "Personal Id in use"
                ? "პირადი ნომერი უკვე გამოყენებულია!"
                : error.data.message}
            </Alert>
          )}

          <div
            className={`input-item ${
              formik.touched.firstName && formik.errors.firstName
                ? "invalid-item"
                : ""
            }`}
          >
            <input
              type="text"
              placeholder="სახელი*"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange("firstName")}
              onBlur={formik.handleBlur("firstName")}
              className={
                formik.errors.firstName && formik.touched.firstName
                  ? "is-invalid"
                  : ""
              }
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <span
                style={{
                  color: "red",
                  marginTop: 5,
                  display: "inline-block",
                  fontSize: 12,
                }}
              >
                სახელი აუცილებელია
              </span>
            ) : null}
          </div>
          <div
            className={`input-item ${
              formik.touched.lastName && formik.errors.lastName
                ? "invalid-item"
                : ""
            }`}
          >
            <input
              type="text"
              placeholder="გვარი*"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange("lastName")}
              onBlur={formik.handleBlur("lastName")}
              className={
                formik.errors.lastName && formik.touched.lastName
                  ? "is-invalid"
                  : ""
              }
            />

            {formik.touched.lastName && formik.errors.lastName ? (
              <span
                style={{
                  color: "red",
                  marginTop: 5,
                  display: "inline-block",
                  fontSize: 12,
                }}
              >
                გვარი აუცილებელია
              </span>
            ) : null}
          </div>
          <div
            className={`input-item ${
              formik.touched.phone && formik.errors.phone ? "invalid-item" : ""
            }`}
          >
            <input
              type="text"
              placeholder="მობ. ნომერი*"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange("phone")}
              onBlur={formik.handleBlur("phone")}
              className={
                formik.errors.phone && formik.touched.phone ? "is-invalid" : ""
              }
            />
            {formik.touched.phone && formik.errors.phone ? (
              <span
                style={{
                  color: "red",
                  marginTop: 5,
                  display: "inline-block",
                  fontSize: 12,
                }}
              >
                ტელეფონი აუცილებელია.
              </span>
            ) : null}
          </div>

          <div
            className={`input-item ${
              formik.touched.phone && formik.errors.phone ? "invalid-item" : ""
            }`}
          >
            <input
              type="email"
              placeholder="ელ. ფოსტა"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              className={
                formik.errors.email && formik.touched.email ? "is-invalid" : ""
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <span
                style={{
                  color: "red",
                  marginTop: 5,
                  display: "inline-block",
                  fontSize: 12,
                }}
              >
                ელ. ფოსტა აუცილებელია, მიუთითეთ სწორი ფორმატით
              </span>
            ) : null}
          </div>
          <div
            className={`input-item ${
              formik.touched.email && formik.errors.email ? "invalid-item" : ""
            }`}
          >
            <input
              type="text"
              placeholder="პირადი ნომერი"
              name="personalId"
              value={formik.values.personalId}
              onChange={formik.handleChange("personalId")}
              onBlur={formik.handleBlur("personalId")}
              className={
                formik.errors.personalId && formik.touched.personalId
                  ? "is-invalid"
                  : ""
              }
            />
            {formik.touched.personalId && formik.errors.personalId ? (
              <span
                style={{
                  color: "red",
                  marginTop: 5,
                  display: "inline-block",
                  fontSize: 12,
                }}
              >
                პირადი ნომერი აუცილებელია, მიუთითეთ სწორი ფორმატით
              </span>
            ) : null}
          </div>
          <div
            className={`input-item ${
              formik.touched.password && formik.errors.password
                ? "invalid-item"
                : ""
            }`}
          >
            <input
              type="password"
              placeholder="პაროლი"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange("password")}
              onBlur={formik.handleBlur("password")}
              className={
                formik.errors.password && formik.touched.password
                  ? "is-invalid"
                  : ""
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <span
                style={{
                  color: "red",
                  marginTop: 5,
                  display: "inline-block",
                  fontSize: 12,
                }}
              >
                პაროლი აუცილებელია
              </span>
            ) : null}
          </div>
          <div
            className={`input-item ${
              formik.touched.agree && formik.errors.agree ? "invalid-item" : ""
            }`}
          >
            <label htmlFor="valid">
              <input
                type="checkbox"
                id="valid"
                onBlur={formik.handleBlur("agree")}
                onChange={(e) => {
                  formik.setFieldValue("agree", e.target.checked);
                }}
              />
              ვარ 18 წლის,
              <Link to="/rules" target="_blank"> ვეთანხმები წესებსა და პირობებს</Link>
            </label>
          </div>
          <div className="action-btns">
            <button
              id="registration-btn"
              type="button"
              className={`action-btn`}
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                console.log("submit");
                formik.submitForm();
              }}
            >
              {/* Object.keys(formik.errors).length */}
              {isLoading ? "Loading..." : "რეგისტრაცია"}
            </button>
            <div
              className="action-btn"
              onClick={() => {
                setVisibleSignIn(true);
                setVisible(false);
              }}
            >
              ავტორიზაცია
            </div>
          </div>
        </div>

        <img src="/images/33.png" className="itemimg" alt="" />
        <img src="/images/bottles.png" className="bottles" alt="" />
      </div>
    </div>
  ) : null;
};
