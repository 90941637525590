import React from "react";
import jwt_decode from "jwt-decode";
import { Header } from "../Components/dashboard/header";
import { ChequeList as DashboardChequeLies } from "./cheque/ChequeList";
import { ChequeContainer as ModeratorChequeList } from "./moderator/cheque/ChequeContainer";

export const ChequeContainer = () => {
  const user = jwt_decode(localStorage.getItem("access_token"));

  return (
    <>
      <div className="dashboard-wrapper">
        <Header />
        <div className="dashboard-content">
          {/**
           * create user Component
           */}
          {/**
           * moderator cheque
           */}
          {user && ["moderator"].includes(user.role) ? (
            <ModeratorChequeList />
          ) : null}
          {/**
           * Admin Cheque List
           */}
          {user && ["admin"].includes(user.role) ? (
            <DashboardChequeLies />
          ) : null}
        </div>
      </div>
    </>
  );
};
