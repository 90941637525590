import React, { useCallback, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { millerApi, usePromoCreateUserMutation } from "../../../api/miller.api";
import { useDispatch } from "react-redux";

export function CreateUser() {
  const dispatch = useDispatch();
  const [createItem, { isLoading: isCreateItemLoading, error, isSuccess }] =
    usePromoCreateUserMutation();
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    personalId: "",
    phone: "",
    email: "",
    point: 0,
  });

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await createItem({
        ...state,
        point: !isNaN(parseInt(state.point)) ? parseInt(state.point) : 0,
      });
      dispatch(millerApi.util.invalidateTags(["PromoRegisteredUsers"]));
      setState((prevState) => ({
        ...prevState,
        firstName: "",
        lastName: "",
        personalId: "",
        phone: "",
        email: "",
        point: 0,
      }));
    },
    [state, dispatch, createItem]
  );

  return (
    <div className="new-user-registration">
      <h2 style={{ fontSize: 24 }}>New user registration</h2> <br />
      {error && error.data && error.data.message && (
        <Alert variant="danger"> {error.data.message} </Alert>
      )}
      {isSuccess && <Alert variant="success"> User Create Successfully </Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.firstName">
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder="John"
            name="firstName"
            value={state.firstName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.lastName">
          <Form.Label>Surname *</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            name="lastName"
            value={state.lastName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.email">
          <Form.Label>Email *</Form.Label>
          <Form.Control
            type="email"
            placeholder=""
            name="email"
            value={state.email}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.personalId">
          <Form.Label>Personal ID *</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            name="personalId"
            value={state.personalId}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.phone">
          <Form.Label>Phone *</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            name="phone"
            value={state.phone}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.point">
          <Form.Label>Point *</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            name="point"
            value={state.point}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.Registration">
          <Button
            variant="success"
            type="submit"
            disabled={
              !state.email ||
              !state.firstName ||
              !state.lastName ||
              !state.phone ||
              isCreateItemLoading
            }
          >
            Registration
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
