import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SignUpModal } from "../SignModal";
import { SignInModal } from "../SignModal/SignIn";
import { QuestionModal } from "../QuestionModal";
import { useDispatch, useSelector } from "react-redux";
import { getMeReselect } from "../../reselects/user.reselect";
import { millerApi, useGetMeQuery } from "../../api/miller.api";
import jwt_decode from "jwt-decode";
import { ForgotPassword } from "../SignModal/ForgotPassword";

const Header = ({
  visibleSignIn,
  setVisibleSignIn,
  visible,
  setVisible,
  visibleForgot,
  setVisibleForgot,
}) => {
  const dispatch = useDispatch();
  const [getMe, { isLoading: isGetMeLoading }] =
    millerApi.endpoints.getMe.useLazyQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [questionVisible, setQuestionVisible] = useState(false);
  let user = useSelector(getMeReselect);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/");

    dispatch(millerApi.util.resetApiState());
  };

  useEffect(() => {
    const question = localStorage.getItem("questionModal");

    if (question === "visible") {
      setQuestionVisible(false);
    } else {
      setQuestionVisible(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access_token") && !user) {
      getMe();
    }
  }, [user, getMe]);

  let userData;
  try {
    userData = jwt_decode(localStorage.getItem("access_token"));
  } catch (error) {}

  return (
    <>
      <div className="header-row header-top">
        <div className="container-item">
          <div className="header">
            <div className="mobile-button" onClick={() => setMobileMenu(true)}>
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.1098 2.29353H0.0705566V0.943237H18.1098V2.29353ZM11.527 9.07668L0.0706475 9.045L0.074435 7.69471L11.5308 7.72639L11.527 9.07668ZM18.1101 15.7965H0.0709172V14.4462H18.1101V15.7965Z"
                  fill="#D0B478"
                />
              </svg>
            </div>
            <div className="left">
              <Link to="/" className="logo-item">
                <img src="/images/logomain.svg" />
              </Link>
              <ul>
                <li>
                  <Link
                    to="/"
                    className={`${location.pathname === "/" ? "active" : ""}`}
                  >
                    მთავარი
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mechanic"
                    className={`${
                      location.pathname === "/mechanic" ? "active" : ""
                    }`}
                  >
                    გათამაშების მექანიკა
                  </Link>
                </li>

                <li>
                  <Link
                    to="/winners"
                    className={`${
                      location.pathname === "/winners" ? "active" : ""
                    }`}
                  >
                    გამარჯვებულები
                  </Link>
                </li>
              </ul>
            </div>

            <div className="mobile-logo" data-user={!!user}>
              <Link to="/" className="logo-item">
                <img src="/images/logomain.svg" />
              </Link>
            </div>

            <div className="right">
              {!isGetMeLoading ? (
                <>
                  {user ? (
                    <>
                      <div className="authed-user">
                        <div className="wellcome">
                          გამარჯობა, {user.firstName}
                        </div>
                        <div className="abbtr">
                          {user.firstName[0]}

                          <div
                            className="logout-popover"
                            data-attr={userData && userData.role !== "user"}
                          >
                            {userData && userData.role === "user" ? (
                              <>
                                <div
                                  className="profile-btn"
                                  onClick={() => navigate("/profile")}
                                >
                                  პროფილი
                                </div>
                                <div
                                  className="profile-btn"
                                  onClick={() => navigate("/settings")}
                                >
                                  პროფილის რედაქტირება
                                </div>
                              </>
                            ) : null}
                            <div
                              className="logout-btn"
                              onClick={() => handleLogout()}
                            >
                              გამოსვლა
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="btn-item hide-mob"
                        onClick={() => setVisible(true)}
                      >
                        რეგისტრაცია
                      </div>
                      <div
                        className="btn-item hide-mob"
                        onClick={() => setVisibleSignIn(true)}
                      >
                        ავტორიზაცია
                      </div>
                      <div
                        className="btn-item show-mob"
                        onClick={() => setVisibleSignIn(true)}
                      >
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_320_44)">
                              <path d="M16.5001 7.40674C19.4721 7.40674 21.8899 9.82453 21.8899 12.7965C21.8899 15.7685 19.4721 18.1863 16.5001 18.1863C13.5281 18.1863 11.1103 15.7685 11.1103 12.7965C11.1103 9.82453 13.5281 7.40674 16.5001 7.40674ZM16.5001 15.8717C18.1957 15.8717 19.5753 14.4921 19.5753 12.7965C19.5753 11.1009 18.1957 9.72137 16.5001 9.72137C14.8045 9.72137 13.425 11.1009 13.425 12.7965C13.425 14.4921 14.8045 15.8717 16.5001 15.8717Z" fill="#D0B478"/>
                              <path d="M4.83229 4.83228C7.94844 1.71613 12.0923 0 16.5 0C20.9077 0 25.0516 1.71613 28.1677 4.83228C31.2839 7.94844 33 12.0923 33 16.5C33 20.9077 31.2839 25.0516 28.1677 28.1677C25.0516 31.2839 20.9077 33 16.5 33C12.0923 33 7.94844 31.2839 4.83229 28.1677C1.71613 25.0516 0 20.9077 0 16.5C0 12.0923 1.71613 7.94844 4.83229 4.83228ZM8.00399 27.8357C10.4886 29.7 13.4268 30.6854 16.5 30.6854C19.5732 30.6854 22.5114 29.7 24.992 27.8357L25.2837 27.6168L25.1151 27.2934C23.889 24.9398 21.495 23.477 18.8682 23.477H14.1272C11.5064 23.477 9.11303 24.9391 7.88164 27.2928L7.71235 27.6168L8.00465 27.8364L8.00399 27.8357ZM5.57429 25.5363L5.94992 25.99L6.26735 25.494C8.00266 22.7812 10.9429 21.1623 14.1318 21.1623H18.8669C22.0544 21.1623 24.9947 22.7812 26.732 25.4933L27.0494 25.9887L27.4251 25.5357C29.5274 23.0008 30.6854 19.7921 30.6854 16.5C30.6854 8.67788 24.3221 2.31463 16.5 2.31463C8.67788 2.31463 2.31463 8.67788 2.31463 16.5C2.31463 19.7881 3.47261 22.9968 5.57429 25.5363Z" fill="#D0B478"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_320_44">
                              <rect width="33" height="33" fill="white" transform="matrix(-1 0 0 1 33 0)"/>
                              </clipPath>
                              </defs>
                              </svg>

                      </div>
                    </>
                  )}
                </>
              ) : (
                "იტვირთება..."
              )}
            </div>
          </div>
        </div>
      </div>
      {mobileMenu ? (
        <div className="fs-menu">
          <div className="close" onClick={() => setMobileMenu(false)}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.8534 16.1465L9.70685 8.99998L16.8534 1.85343C17.0406 1.65971 17.0406 1.35252 16.8534 1.15873C16.6616 0.960121 16.345 0.954627 16.1464 1.14646L8.99988 8.29301L1.85339 1.14652C1.65967 0.959388 1.35248 0.959388 1.15869 1.14652C0.960083 1.33836 0.95459 1.65488 1.14642 1.85349L8.29291 8.99998L1.14642 16.1465C1.05267 16.2402 1 16.3673 1 16.4999C0.999939 16.776 1.22382 16.9999 1.49994 17C1.63257 17.0001 1.75977 16.9474 1.85339 16.8534L8.99988 9.70695L16.1464 16.8535C16.2401 16.9474 16.3673 17.0002 16.4999 17C16.6324 16.9999 16.7595 16.9473 16.8533 16.8536C17.0486 16.6584 17.0486 16.3418 16.8534 16.1465Z"
                fill="#D0B478"
                stroke="#D0B478"
              />
            </svg>
          </div>

          <ul>
            <li>
              <Link
                to="/"
                className={`${location.pathname === "/" ? "active" : ""}`}
              >
                მთავარი
              </Link>
            </li>
            <li>
              <Link
                to="/mechanic"
                className={`${
                  location.pathname === "/mechanic" ? "active" : ""
                }`}
              >
                გათამაშების მექანიკა
              </Link>
            </li>

            <li>
              <Link
                to="/winners"
                className={`${
                  location.pathname === "/winners" ? "active" : ""
                }`}
              >
                გამარჯვებულები
              </Link>
            </li>
            {!user && (
              <>
                <li>
                  <div
                    className="btn-item"
                    onClick={() => {
                      setVisible(true);
                      setMobileMenu(false);
                    }}
                  >
                    რეგისტრაცია
                  </div>
                </li>
                <li>
                  <div
                    className="btn-item"
                    onClick={() => {
                      setVisibleSignIn(true);
                      setMobileMenu(false);
                    }}
                  >
                    ავტორიზაცია
                  </div>
                </li>
              </>
            )}

            <li
              style={{
                marginTop: "50px",
              }}
            >
              <Link to="/" className="logo-item">
                <img src="/images/logomain.svg" />
              </Link>
            </li>
          </ul>
        </div>
      ) : null}

      <SignUpModal
        visible={visible}
        setVisible={setVisible}
        setVisibleSignIn={setVisibleSignIn}
      />
      <SignInModal
        visible={visibleSignIn}
        setVisible={setVisibleSignIn}
        setSignUpVisible={setVisible}
        setVisibleForgot={setVisibleForgot}
      />
      <ForgotPassword visible={visibleForgot} setVisible={setVisibleForgot} />
      {questionVisible ? (
        <QuestionModal setQuestionVisible={setQuestionVisible} />
      ) : null}
    </>
  );
};
export default Header;
