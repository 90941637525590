import React from "react";
import { CreateUser } from "./createUser";
// import DataTable from "react-data-table-component";
// import { useDispatch } from "react-redux";
// import {
//   millerApi,
//   usePromoRegisteredUsersQuery,
// } from "../../../api/miller.api";
// import { useDebouncedCallback } from "use-debounce";
// import { Loader } from "../../../Components/Loader";

export const UsersContainer = () => {
  // const dispatch = useDispatch();

  // const [, setState] = useState({
  //   search: "",
  //   skip: 0,
  //   take: 20,
  // });

  // const { isLoading, data } = usePromoRegisteredUsersQuery({
  //   search: state.search,
  //   skip: state.skip,
  //   take: state.take,
  // });

  // const columns = [
  //   {
  //     name: "Point",
  //     selector: (row) => row.point,
  //     sortable: true,
  //   },
  //   {
  //     name: "First Name",
  //     selector: (row) => `${row.firstName ? row.firstName : ""}`,
  //     sortable: true,
  //   },
  //   {
  //     name: "Last Name",
  //     selector: (row) => `${row ? row.lastName : ""}`,
  //     sortable: true,
  //   },
  //   {
  //     name: "Email",
  //     selector: (row) => `${row ? row.email : ""}`,
  //     sortable: true,
  //   },
  //   {
  //     name: "Verified Date",
  //     selector: (row) => row.emailVerifiedAt,
  //     sortable: true,
  //   },

  //   {
  //     name: "Status",
  //     selector: (row) =>
  //       row.status === "approved" ? (
  //         <span style={{ color: "#00a558", fontWeight: "bold" }}>
  //           {row.status === "approved" ? "Approved" : ""}
  //         </span>
  //       ) : row.status === "inprogress" ? (
  //         <span style={{ color: "orange", fontWeight: "bold" }}>
  //           {row.status === "inprogress" ? "Inprogress" : ""}
  //         </span>
  //       ) : row.status === "rejected" ? (
  //         <span style={{ color: "red", fontWeight: "bold" }}>
  //           {" "}
  //           {row.status === "rejected" ? "Rejected" : ""}
  //         </span>
  //       ) : null,

  //     sortable: true,
  //   },
  // ];

  // const loadItems = useCallback(
  //   async (perPage) => {
  //     setState((prevState) => ({
  //       ...prevState,
  //       skip:
  //         perPage > 1
  //           ? prevState.skip + prevState.take
  //           : prevState.skip - prevState.take,
  //     }));
  //     dispatch(millerApi.util.invalidateTags(["PromoRegisteredUsers"]));
  //   },
  //   [dispatch]
  // );

  // Transform data

  // const handleChangeSearch = useDebouncedCallback((e) => {
  //   const { name, value } = e.target;
  //   setState((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // }, 1000);

  // const tHead = useMemo(() => {
  //   return (
  //     <div className="filter-side">
  //       <input
  //         placeholder="For search name, lastname, id, email..."
  //         onChange={handleChangeSearch}
  //         name="search"
  //       />
  //     </div>
  //   );
  // }, [handleChangeSearch]);

  return (
    <div className="card-block receipt-table">
      <CreateUser />
      <br />
      {/* <DataTable
        title="All Users"
        subHeader
        subHeaderComponent={tHead}
        progressPending={isLoading}
        progressComponent={<Loader />}
        columns={columns}
        data={data}
        //
        pagination
        paginationServer
        paginationTotalRows={
          state.skip + state.take + (data && data.length < 20 ? 0 : 1)
        }
        paginationPerPage={20}
        paginationComponentOptions={{
          noRowsPerPage: false,
          selectAllRowsItem: false,
          rowsPerPageText: "",
        }}
        paginationIconFirstPage={null}
        paginationIconLastPage={null}
        onChangePage={loadItems}
      /> */}
    </div>
  );
};
