import { useState } from "react";

export function QuestionModal({ setQuestionVisible }) {
  const [answer, setAnswer] = useState(false);
  const handleClick = () => {
    localStorage.setItem("questionModal", "visible");
    setQuestionVisible(false);
  };
  const handleClickNo = () => {
    setAnswer(true);
  };
  return (
    <div className="question-modal">
      <div className="question-modal-content">
       

        <h4>
          ვებსაიტი განკუთვნილია მხოლოდ სრულწლოვანი პირებისთვის. ხართ
          სრულწლოვანი?
        </h4>
        {answer ? (
          <p style={{ color: "red", marginTop: 15 }}>
            საიტი განკუთვნილია მხოლოდ 18+ მოხმარებლებისთვის.
          </p>
        ) : null}

        <div className="buttons-wrapper">
          <div className="btn" onClick={() => handleClickNo()}>
            არა
          </div>
          <div className="btn" onClick={() => handleClick()}>
            დიახ
          </div>
        </div>
      </div>
    </div>
  );
}
