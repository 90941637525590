import React from "react";
import jwt_decode from "jwt-decode";

import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./Components/PrivateRoute";

import Dashboard from "./Dashboard";
import SignIn from "./Dashboard/SignIn";
import { ChequeContainer } from "./Dashboard/ChequeContainer";
import { LeaderBoards } from "./Dashboard/leaderboards";

import "bootstrap/dist/css/bootstrap.min.css";
import { WinnerContainer } from "./Dashboard/Winners";
import { MainContainer } from "./pages/MainContainer";

import "./styles/App.css";
import { VerificationContainer } from "./pages/VerificationContainer";
import { UserProfile } from "./pages/profile/UserProfile";
import { Rules } from "./pages/Rules";
import { Mechanic } from "./pages/Mechanic";
import { Prizes } from "./pages/Prizes";
import { Winners } from "./pages/Winners";
import { ChangePasswordContainer } from "./pages/profile/Settings/ChangePasswordContainer";
import Thank from "./pages/Thank";
import { PasswordResetContainer } from "./pages/PasswordResetContainer";

const App = () => {
  let user = null;

  try {
    user = jwt_decode(localStorage.getItem("access_token"));
  } catch (error) {}

  return (
    <>
      <Routes>
        <Route path="/" element={<MainContainer />} />
        <Route path="/verify/:code" element={<VerificationContainer />} />
        <Route
          path="/forgot-password/:code"
          element={<PasswordResetContainer />}
        />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/mechanic" element={<Mechanic />} />
        <Route path="/prizes" element={<Prizes />} />
        <Route path="/winners" element={<Winners />} />
        <Route path="/thank" element={<Thank />} />

        <Route
          path="/profile"
          element={
            <PrivateRoute rollBackUrl="/">
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute rollBackUrl="/">
              <ChangePasswordContainer />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {user && ["admin", "moderator"].includes(user.role) ? (
          <Route
            path="/dashboard/cheques"
            element={
              <PrivateRoute>
                <ChequeContainer />
              </PrivateRoute>
            }
          />
        ) : null}

        {user && ["admin"].includes(user.role) ? (
          <>
            <Route
              path="/dashboard/leader-boards"
              element={
                <PrivateRoute>
                  <LeaderBoards />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/leader-boards/v2"
              element={
                <PrivateRoute>
                  <LeaderBoards />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/winners"
              element={
                <PrivateRoute>
                  <WinnerContainer />
                </PrivateRoute>
              }
            />
          </>
        ) : null}
      </Routes>
    </>
  );
};

export default App;
