import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = jwt_decode(localStorage.getItem("access_token"));

  const logOut = useCallback(() => {
    localStorage.removeItem("access_token");
    navigate("/signin");
  }, [navigate]);

  return (
    <div className="header-wrapper">
      <div className="header-in">
        <ul>
          <li className={`${pathname === "/dashboard" ? "active" : ""}`}>
            <Link to="/dashboard">Home</Link>
          </li>

          {user && ["admin"].includes(user.role) ? (
            <li
              className={`${
                pathname === "/dashboard/leader-boards" ? "active" : ""
              }`}
            >
              <Link to="/dashboard/leader-boards">Leader Boards</Link>
            </li>
          ) : null}
          {user && ["admin"].includes(user.role) ? (
            <li
              className={`${pathname === "/dashboard/cheques" ? "active" : ""}`}
            >
              <Link to="/dashboard/cheques">Receipts</Link>
            </li>
          ) : null}

          {user && ["admin"].includes(user.role) ? (
            <li
              className={`${pathname === "/dashboard/winners" ? "active" : ""}`}
            >
              <Link to="/dashboard/winners">Winners</Link>
            </li>
          ) : null}
        </ul>

        <button type="button" className="primary" onClick={logOut}>
          Log out
        </button>
      </div>
    </div>
  );
};
