import React, { useCallback, useState } from "react";
// import { signIn } from "../api/base.api";
import { useNavigate } from "react-router";
import { millerApi, useSignInMutation } from "../api/miller.api";
import { useDispatch } from "react-redux";

const SignIn = () => {
  const dispatch = useDispatch();
  const [signIn, { isLoading }] = useSignInMutation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSignIn = useCallback(async () => {
    if (email.trim() === "" || password.trim() === "") {
      setError("Please enter both, username and password.");
      return;
    }

    try {
      dispatch(millerApi.util.resetApiState());
      const { data } = await signIn({ email, password });

      localStorage.setItem("access_token", data.access_token);

      navigate("/dashboard");
    } catch (error) {
      setError(error.message);
    }
  }, [email, password, signIn, navigate]);

  return (
    <div className="signIn-wrapper">
      <div className="signIn-card">
        <h3>Authorization</h3>

        <div className="signIn-form">
          <div className="form-item">
            <input
              placeholder="Username"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(false);
              }}
            />
          </div>
          <div className="form-item">
            <input
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
              }}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <div className="form-item">
            <button type="button" onClick={handleSignIn} disabled={isLoading}>
              {isLoading ? "Loading..." : "Log in"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
