import React from "react";

export const ProfileChequeItem = ({ item }) => {
  return (
    <div className="list-item">
      {item.status === "inprogress" ? (
        <div className="list-item-info" style={{ color: "#ffc107" }}>
          მოწმდება
        </div>
      ) : null}
      {item.status === "rejected" ? (
        <div className="list-item-info" style={{ color: "red" }}>
          გაუქმებულია
        </div>
      ) : null}
      {item.status === "approved" ? (
        <div className="list-item-info" style={{ color: "#20c997" }}>
          დამოწმებული
        </div>
      ) : null}
      <div className="list-item-info">{item.point} ქულა</div>
      <div className="list-item-info">{item.note}</div>
      <div className="list-item-info">{item.createdAt}</div>
      <div className="list-item-info">
        <img src={item.url} alt={`${item.image}.png`} />

        <div className="popover">
          <img src={item.url} alt={`${item.image}.png`} />
        </div>
      </div>
    </div>
  );
};
