import React from "react";
import { Spinner } from "react-bootstrap";

export const Loader = ({ isLoading }) => {
  return (
    isLoading && (
      <h5
        style={{
          margin: "50px 0",
          border: "1px solid #ccc",
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        Loading ...
      </h5>
    )
  );
};

export const MySpinner = ({ isLoading }) => {
  return (
    isLoading && (
      <Spinner
        // animation="border"
        role="status"
        variant="warning"
        animation="grow"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  );
};
