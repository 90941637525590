import React from "react";
import jwt_decode from "jwt-decode";
import { Header } from "../Components/dashboard/header";
import { WinnerList } from "./winner/WinnerList";

export const WinnerContainer = () => {
  const user = jwt_decode(localStorage.getItem("access_token"));

  return (
    <>
      <div className="dashboard-wrapper">
        <Header />
        <div className="dashboard-content">
          {/**
           * create user Component
           */}
          {/**
           * moderator cheque
           */}
          {/**
           * Admin Cheque List
           */}
          {user && ["admin"].includes(user.role) ? <WinnerList /> : null}
        </div>
      </div>
    </>
  );
};
