import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const headers = () => ({
  Authorization: "Bearer " + localStorage.getItem("access_token"),
});

export const millerApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: window.config.apiUrl }),
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: ({ email, password }) => ({
        url: `/v1/auth/signin`,
        method: "POST",
        body: {
          email,
          password,
        },
        headers: headers(),
      }),
    }),
    signUp: builder.mutation({
      query: ({ firstName, lastName, phone, personalId, email, password }) => ({
        url: `/v1/auth/signup`,
        method: "POST",
        body: {
          firstName,
          lastName,
          phone,
          personalId,
          email,
          password,
        },
        headers: headers(),
      }),
    }),

    forgotPassword: builder.mutation({
      query: ({ email, password }) => ({
        url: `/v1/auth/forgot-password`,
        method: "POST",
        body: {
          email,
          password,
        },
        headers: headers(),
      }),
    }),
    validateCode: builder.query({
      query: ({ code }) => ({
        url: `/v1/auth/validation-reset-password-code?code=${code}`,
        headers: headers(),
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ code, password }) => ({
        url: `/v1/auth/reset-password`,
        method: "POST",
        body: {
          code,
          password,
        },
        headers: headers(),
      }),
    }),

    verifyEmail: builder.query({
      query: (code = "") => ({
        url: `/v1/auth/email/verify?code=${code}`,
        headers: headers(),
      }),
    }),

    getMe: builder.query({
      query: () => ({
        url: `/v1/auth/me`,
        headers: headers(),
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["GET_ME"],
    }),

    updateMe: builder.mutation({
      query: (body) => ({
        method: "PATCH",
        url: `/v1/auth/me`,
        body: {
          ...body,
        },
        headers: headers(),
      }),
      // providesTags: ["UserME"],
    }),

    uploadFile: builder.mutation({
      query: (file) => {
        const body = new FormData();
        body.append("file", file);

        return {
          url: `/v1/file`,
          method: "POST",
          body,
          headers: headers(),
        };
      },
    }),

    /**
     * User side
     */

    userUploadCheque: builder.mutation({
      query: (image) => ({
        url: `/v1/cheque`,
        method: "POST",
        body: {
          image,
        },
        headers: headers(),
      }),
    }),

    userCheques: builder.query({
      query: ({ search = "", skip = 0, take = 20 }) => {
        let query = {};

        if (search) query.search = search;

        query.skip = skip;
        query.take = take;
        return {
          url: `/v1/cheque?${new URLSearchParams(query)}`,
          headers: headers(),
        };
      },
      providesTags: ["UserCheques"],
    }),

    /**
     *
     * Admin side
     */
    sendGiftToUser: builder.mutation({
      query: ({ id, ...rest } = {}) => ({
        url: `/v2/dashboard/gift/send/${id}`,
        method: "POST",
        body: {
          ...rest,
        },
        headers: headers(),
      }),
    }),
    getUsers: builder.query({
      query: ({ search = "", skip = 0, take = 20 }) => {
        let query = {};

        if (search) query.search = search;

        query.skip = skip;
        query.take = take;
        return {
          url: `/v1/dashboard/user?${new URLSearchParams(query)}`,
          headers: headers(),
        };
      },
      providesTags: ["Users"],
    }),
    getCheques: builder.query({
      query: ({ search = "", skip = 0, take = 20 }) => {
        let query = {};

        if (search) query.search = search;

        query.skip = skip;
        query.take = take;
        return {
          url: `/v1/dashboard/cheque?${new URLSearchParams(query)}`,
          headers: headers(),
        };
      },
      providesTags: ["Cheque"],
    }),

    getWinners: builder.query({
      query: ({ type = "weekly", skip = 0, take = 20 }) => {
        let query = {};

        // if (type) query.type = type;

        query.skip = skip;
        query.take = take;

        return {
          url: `/v1/dashboard/gift/winners?${new URLSearchParams(query)}`,
          headers: headers(),
        };
      },
      // transformResponse: (result, arg, meta) => {
      //   const winners = [];
      //   result.map((res) => {
      //     res.winners.map((win) => {
      //       winners.push({
      //         ...win,
      //         startDate: res.startDate,
      //         endDate: res.endDate,
      //         type: res.type,
      //       });
      //       return win;
      //     });
      //     return res;
      //   });
      //   return winners;
      // },
      providesTags: ["Winners"],
    }),
    getGiftsForUser: builder.query({
      query: ({ search = "", type = "", skip = 0, take = 20 }) => ({
        method: "GET",
        url: `/v1/dashboard/gift?${new URLSearchParams({
          skip,
          take,
          type,
        })}`,
        headers: headers(),
      }),
      providesTags: ["UserGifts"],
    }),

    getClientAnalytics: builder.query({
      query: ({ type = "weekly", v = 1, includeDate = false }) => ({
        method: "GET",
        url: `/v${v}/analytic/client?${new URLSearchParams({
          type,
          includeDate,
        })}`,
        headers: headers(),
      }),
      providesTags: ["ClientAnalytics"],
    }),

    getClientWinnersAnalytics: builder.query({
      query: () => ({
        method: "GET",
        url: `/v1/analytic/winners`,
        headers: headers(),
      }),
      providesTags: ["ClientWinnersAnalytics"],
    }),

    getAnalytics: builder.mutation({
      query: ({ type = "weekly", v = 1, date = null }) => ({
        method: "GET",
        url: `/v${v}/analytic?${new URLSearchParams({
          type,
          date,
        })}`,
        headers: headers(),
      }),
      providesTags: ["Analytics"],
    }),
    getGiftedUsersAnalytics: builder.query({
      query: ({ type = "weekly", v = 2, date = null }) => ({
        method: "GET",
        url: `/v${v}/analytic/gifted?${new URLSearchParams({
          type,
          date,
        })}`,
        headers: headers(),
      }),
      providesTags: ["AnalyticsOfGiftedUsers"],
    }),
    exportUsers: builder.query({
      query: () => ({
        url: `/v1/dashboard/user/export`,
        headers: headers(),
        responseHandler: async (response) =>
          window.location.assign(
            window.URL.createObjectURL(await response.blob())
          ),
        cache: "no-cache",
      }),
    }),

    updateUser: builder.mutation({
      query: ({ id, ...rest } = {}) => ({
        url: `/v1/dashboard/user/${id}`,
        method: "PATCH",
        body: {
          ...rest,
        },
        headers: headers(),
      }),
    }),
    sendGift: builder.mutation({
      query: ({ status = "weekly", v = 1, date = null }) => ({
        url: `/v${v}/dashboard/gift/send`,
        body: {
          status,
          date,
        },
        method: "POST",
        headers: headers(),
      }),
    }),

    chequeUpdate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/v1/dashboard/cheque/${id}`,
        body: {
          ...body,
        },
        method: "PATCH",
        headers: headers(),
      }),
    }),

    /**
     * Moderator
     */

    moderatoRandomUser: builder.query({
      query: () => ({
        url: `/v1/moderator/user/random`,
        keepUnusedDataFor: 5,
        headers: headers(),
      }),
      providesTags: ["ModeratorRandomUser"],
    }),

    moderatorUsers: builder.query({
      query: ({ search = "", skip = 0, take = 20 }) => ({
        url: `/v1/moderator/user?${new URLSearchParams({
          skip,
          take,
          search,
        })}`,
        headers: headers(),
      }),
      providesTags: ["ModeratorUsers"],
    }),

    moderatorUpdateUser: builder.mutation({
      query: ({ id, ...rest } = {}) => ({
        url: `/v1/moderator/user/${id}`,
        method: "PATCH",
        body: {
          ...rest,
        },
        headers: headers(),
      }),
    }),

    moderatoRandomCheque: builder.query({
      query: () => ({
        url: `/v1/moderator/cheque/random`,
        headers: headers(),
      }),
      providesTags: ["ModeratorRandomCheque"],
    }),

    moderatorCheque: builder.query({
      query: ({ search = "", skip = 0, take = 20 }) => ({
        url: `/v1/moderator/cheque?${new URLSearchParams({
          skip,
          take,
          search,
        })}`,
        headers: headers(),
      }),
      providesTags: ["ModeratorCheque"],
    }),

    moderatorUpdateCheque: builder.mutation({
      query: ({ id, point = 0, ...rest } = {}) => ({
        url: `/v1/moderator/cheque/${id}`,
        method: "PATCH",
        body: {
          point,
          ...rest,
        },
        headers: headers(),
      }),
    }),

    /**
     * Promo agent side
     */

    promoRegisteredUsers: builder.query({
      query: ({ search = "", skip = 0, take = 20 }) => ({
        url: `/v1/promo/user?${new URLSearchParams({
          skip,
          take,
          search,
        })}`,
        headers: headers(),
      }),
      providesTags: ["PromoRegisteredUsers"],
    }),

    promoCreateUser: builder.mutation({
      query: ({ ...body }) => ({
        url: `/v1/promo/user`,
        method: "POST",
        body: {
          ...body,
        },
        headers: headers(),
      }),
    }),
  }),
});

const {
  useSignInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useLazyValidateCodeQuery,
  useResetPasswordMutation,
  useVerifyEmailQuery,
  useGetMeQuery,
  useUpdateMeMutation,
  useUploadFileMutation,

  // User
  useUserUploadChequeMutation,
  useUserChequesQuery,
  useGetClientWinnersAnalyticsQuery,
  // dashboard
  useSendGiftToUserMutation,
  useGetUsersQuery,
  useGetClientAnalyticsQuery,
  useGetAnalyticsMutation,
  useGetGiftedUsersAnalyticsQuery,
  useGetChequesQuery,
  useGetGiftsForUserQuery,
  useGetWinnersQuery,
  useExportUsersQuery,
  useUpdateUserMutation,
  useSendGiftMutation,
  useChequeUpdateMutation,
  // moderator
  useModeratoRandomUserQuery,
  useModeratorUsersQuery,
  useModeratorUpdateUserMutation,
  useModeratoRandomChequeQuery,
  useModeratorChequeQuery,
  useModeratorUpdateChequeMutation,
  // promo agent
  usePromoRegisteredUsersQuery,
  usePromoCreateUserMutation,
} = millerApi;

export {
  useSignInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useVerifyEmailQuery,
  useLazyValidateCodeQuery,
  useResetPasswordMutation,
  useGetMeQuery,
  useUpdateMeMutation,
  useUploadFileMutation,
  // User
  useUserUploadChequeMutation,
  useUserChequesQuery,
  useGetClientWinnersAnalyticsQuery,
  // dashboard
  useSendGiftToUserMutation,
  useGetUsersQuery,
  useGetClientAnalyticsQuery,
  useGetAnalyticsMutation,
  useGetGiftedUsersAnalyticsQuery,
  useGetChequesQuery,
  useGetGiftsForUserQuery,
  useGetWinnersQuery,
  useExportUsersQuery,
  useUpdateUserMutation,
  useSendGiftMutation,
  useChequeUpdateMutation,
  // moderator
  useModeratoRandomUserQuery,
  useModeratorUsersQuery,
  useModeratorUpdateUserMutation,
  useModeratoRandomChequeQuery,
  useModeratorChequeQuery,
  useModeratorUpdateChequeMutation,
  // promo agent
  usePromoRegisteredUsersQuery,
  usePromoCreateUserMutation,
};
