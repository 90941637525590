import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  millerApi,
  useGetAnalyticsMutation,
  useSendGiftMutation,
} from "../../api/miller.api";
import DataTable from "react-data-table-component";
import { Button, Alert } from "react-bootstrap";
import { Loader } from "../../Components/Loader";

export const LeaderBoardList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    search: "",
    type: "weekly",
  });

  const [fetchAnalytics, { isLoading, data }] = useGetAnalyticsMutation(
    state.type
  );

  const [sendGifts, { isLoading: isSendGiftLoading, error: sendGiftError }] =
    useSendGiftMutation(state.type);

  const handleChangeType = useCallback(
    (e) => {
      let type = e.target.value;
      if (type) {
        setState((prevState) => ({
          ...prevState,
          type,
        }));
        fetchAnalytics(type);
      }
    },
    [fetchAnalytics]
  );

  useEffect(() => {
    fetchAnalytics();
  }, [fetchAnalytics]);

  const handleSendGift = useCallback(() => {
    if (
      window.confirm(`Are you sure to send a gift to ${state.type} winners`)
    ) {
      sendGifts({ status: state.type });
      dispatch(millerApi.util.invalidateTags(["Analytics"]));
    }
  }, [state.type, sendGifts, dispatch]);

  /**
   * Transform date
   */

  const columns = [
    {
      name: "Points",
      selector: (row) => row.points,
      sortable: true,
    },
    {
      name: "Personal ID",
      selector: (row) => row.user.personalId,
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => row.user.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.user.lastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.user.phone,
      sortable: true,
    },
  ];

  const handleChangeSearch = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const tHead = useMemo(() => {
    return (
      <div className="filter-side">
        {sendGiftError && sendGiftError.data && (
          <Alert variant="warning"> {sendGiftError.data.message}</Alert>
        )}
        <Button
          className="approve-btn"
          style={{
            border: "0",
            fontSize: "12px",
            margin: "0 5px",
            marginRight: "15px",
            height: "40px",
            position: "relative",
            top: "-2px",
          }}
          disabled={isSendGiftLoading || sendGiftError}
          onClick={() => handleSendGift()}
        >
          Send Gift To Users
        </Button>

        <select
          name="type"
          id=""
          className="select-item"
          defaultValue={state.type}
          onChange={handleChangeType}
        >
          <option value="weekly">Current Week</option>
          <option value="campaign">Full Campaign</option>
        </select>
        <input
          placeholder="For search name, lastname, email..."
          onChange={handleChangeSearch}
          name="search"
          value={state.search}
        />
      </div>
    );
  }, [
    sendGiftError,
    isSendGiftLoading,
    state.search,
    state.type,
    handleSendGift,
    handleChangeSearch,
    handleChangeType,
  ]);

  const filteredData = state.search
    ? data.filter(
        (i) =>
          i.points === parseInt(state.search) ||
          i.user.firstName.toLowerCase().includes(state.search.toLowerCase()) ||
          i.user.lastName.toLowerCase().includes(state.search.toLowerCase()) ||
          i.user.email.toLowerCase().includes(state.search.toLowerCase()) ||
          i.user.phone.trim() === state.search.trim()
      )
    : data;

  return (
    <div className="card-block">
      <DataTable
        title="Leader boards"
        subHeader
        subHeaderComponent={tHead}
        progressPending={isLoading}
        progressComponent={<Loader />}
        columns={columns}
        data={filteredData}
      />
      {isLoading ? <h3 className="loading-item">Loading...</h3> : null}
    </div>
  );
};
