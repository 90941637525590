import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGetAnalyticsMutation,
  useGetGiftedUsersAnalyticsQuery,
  useGetGiftsForUserQuery,
  useSendGiftMutation,
  useSendGiftToUserMutation,
} from "../../api/miller.api";
import DataTable from "react-data-table-component";
import { Button, Alert, Modal, Form } from "react-bootstrap";
import { Loader } from "../../Components/Loader";

export const LeaderBoardListV2 = () => {
  const [state, setState] = useState({
    userId: null,
    showModal: false,
    selected: null,
    search: "",
    type: "weekly",
    date: "",
    skip: 0,
    take: 20,
    v: 2,
    sendGiftToUserError: null,
  });

  const {
    isLoading: isUserGiftsLoading,
    data: gifts,
    refetch: refetchGiftsForUser,
  } = useGetGiftsForUserQuery({
    skip: state.skip,
    take: state.take,
    type: ["weekly", "PreviousWeek", "customize"].includes(state.type)
      ? "weekly"
      : state.type,
  });

  const [fetchAnalytics, { isLoading, data }] = useGetAnalyticsMutation();

  const [
    sendGifts,
    {
      isLoading: isSendGiftLoading,
      error: sendGiftError,
      isSuccess: isSendGiftSuccess,
    },
  ] = useSendGiftMutation();

  const [
    sendGiftToUser,
    {
      isLoading: isSendGiftToUserLoading,
      isSuccess: isSendGiftToUserSuccess,
      error: sendGiftToUserError,
    },
  ] = useSendGiftToUserMutation();

  const {
    isLoading: isGiftedUsersAnalyticLoading,
    refetch: refetchGiftedUsers,
    data: giftedUsers,
  } = useGetGiftedUsersAnalyticsQuery({
    type: state.type,
    date: state.date,
  });

  const handleSendGiftToUser = useCallback(async () => {
    const { userId, selected, type, date } = state;
    try {
      await sendGiftToUser({
        id: userId,
        gift: selected,
        status: type,
        date,
      });
    } catch (error) {}
  }, [state, sendGiftToUser]);

  const handleChangeType = useCallback(
    (e) => {
      let type = e.target.value;
      if (type) {
        setState((prevState) => ({
          ...prevState,
          type,
        }));
        fetchAnalytics({ type, v: 2 });
      }
    },
    [fetchAnalytics]
  );

  useEffect(() => {
    if (
      state.type === "customize" &&
      state.date &&
      /^\d{4}-\d{2}-\d{2}$/.test(state.date)
    ) {
      fetchAnalytics({ type: state.type, v: state.v, date: state.date });
    } else if (state.type !== "customize") {
      fetchAnalytics({ type: state.type, v: state.v });
    }
  }, [state.date, state.type, state.v, fetchAnalytics]);

  useEffect(() => {
    if (isSendGiftSuccess) {
      fetchAnalytics({ type: state.type, v: 2 });
      refetchGiftedUsers();
    }
  }, [state.type, isSendGiftSuccess, fetchAnalytics, refetchGiftedUsers]);

  const handleSendGift = useCallback(() => {
    if (
      window.confirm(`Are you sure to send gift to ${state.type} winners ?`)
    ) {
      sendGifts({ status: state.type, v: 2, date: state.date });
    }
  }, [state.date, state.type, sendGifts]);

  const handleToggleModal = useCallback((userId) => {
    if (!userId) return;
    setState((prevState) => ({
      ...prevState,
      userId,
      selected: null,
      showModal: true,
    }));
  }, []);

  const handleCloseModal = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      selected: null,
      showModal: false,
    }));
  }, []);

  const handleSelected = useCallback((e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      selected: value,
      sendGiftToUserError: null,
    }));
  }, []);

  // close after success
  useEffect(() => {
    if (isSendGiftToUserSuccess) {
      handleCloseModal();

      fetchAnalytics({ type: state.type, v: 2, date: state.date });
      refetchGiftedUsers();
      refetchGiftsForUser();
    }
  }, [
    state.date,
    state.type,
    isSendGiftToUserSuccess,
    fetchAnalytics,
    refetchGiftedUsers,
    refetchGiftsForUser,
    handleCloseModal,
  ]);
  useEffect(() => {
    if (
      sendGiftToUserError &&
      sendGiftToUserError.data &&
      sendGiftToUserError.data.message
    ) {
      setState((prevState) => ({
        ...prevState,
        sendGiftToUserError: sendGiftToUserError.data.message,
      }));
    }
  }, [sendGiftToUserError]);

  /**
   * Transform date
   */

  const BaseColumns = [
    {
      name: "Points",
      selector: (row) => row.points,
      sortable: true,
    },
    {
      name: "Personal ID",
      selector: (row) => row.user.personalId,
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => row.user.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.user.lastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.user.phone,
      sortable: true,
    },
  ];

  const leaderBoardsColumns = [
    ...BaseColumns,
    {
      name: "Actions",
      selector: (row) => {
        return (
          <>
            {row.user &&
              ((!row.user.gift &&
                ["PreviousWeek", "weekly", "customize"].includes(state.type)) ||
                (!row.user.finalGift && state.type === "campaign")) && (
                <Button
                  className="approve-btn"
                  style={{
                    border: "0",
                    fontSize: "14px",
                    margin: "0 5px",
                  }}
                  onClick={() => handleToggleModal(row.user.id)}
                  disabled={isSendGiftToUserLoading}
                >
                  Send Gift
                </Button>
              )}
          </>
        );
      },
      sortable: false,
    },
  ];

  const winnerUsersColumn = [
    ...BaseColumns,
    {
      name: "Gift",
      selector: (row) => {
        return (
          <>
            {row.user &&
            row.user.gift &&
            row.user.gift.title &&
            state.type === "weekly"
              ? row.user.gift.title
              : null}

            {row.user &&
            row.user.finalGift &&
            row.user.finalGift.title &&
            state.type === "campaign"
              ? row.user.finalGift.title
              : null}
          </>
        );
      },
      sortable: false,
    },
  ];

  const handleChangeSearch = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleDate = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  // const handleCustomSearch = useCallback(() => {
  //   const { type, date } = state;
  //   fetchAnalytics({ type, v: 2, date: `${date}T00:00:00` });
  //   refetchGiftedUsers();
  //   refetchGiftsForUser();
  // }, [state, refetchGiftedUsers, refetchGiftsForUser, fetchAnalytics]);

  const tHead = useMemo(() => {
    return (
      <div className="filter-side">
        {sendGiftError && sendGiftError.data && (
          <Alert variant="warning"> {sendGiftError.data.message}</Alert>
        )}

        <Button
          className="approve-btn"
          style={{
            border: "0",
            fontSize: "12px",
            margin: "0 5px",
            marginRight: "15px",
            height: "40px",
            position: "relative",
            top: "-2px",
          }}
          disabled={isSendGiftLoading || sendGiftError}
          onClick={() => handleSendGift()}
        >
          Generate {state.type} winners
        </Button>

        <select
          name="type"
          id=""
          className="select-item"
          defaultValue={state.type}
          onChange={handleChangeType}
        >
          <option value="customize">Customize</option>
          <option value="PreviousWeek">Previous Week</option>

          <option value="weekly" selected>
            Current Week
          </option>
          <option value="campaign">Full Campaign</option>
        </select>

        {state.type === "customize" ? (
          <>
            <Form.Control
              placeholder="2024-07-31"
              type="text"
              name="date"
              value={state.date}
              onChange={handleDate}
              isInvalid={state.date && !/^\d{4}-\d{2}-\d{2}$/.test(state.date)}
              isValid={state.date && /^\d{4}-\d{2}-\d{2}$/.test(state.date)}
              style={{
                display: "inline",
                width: 345,
                marginRight: 5,
              }}
            />
            {/* 
            <Button
              className="approve-btn"
              style={{
                border: "0",
                fontSize: "12px",
                margin: "0 5px",
                marginRight: "15px",
                height: "40px",
                position: "relative",
                top: "-2px",
              }}
              disabled={
                state.type !== "customize" ||
                !state.date ||
                !/^\d{4}-\d{2}-\d{2}$/.test(state.date)
              }
              onClick={handleCustomSearch}
            >
              Search
            </Button> */}
          </>
        ) : null}

        <input
          placeholder="For search name, lastname, email..."
          onChange={handleChangeSearch}
          name="search"
          value={state.search}
        />
      </div>
    );
  }, [
    sendGiftError,
    isSendGiftLoading,
    state.search,
    state.type,
    state.date,
    handleDate,
    handleSendGift,
    handleChangeSearch,
    handleChangeType,
  ]);

  const filteredData = state.search
    ? data.filter(
        (i) =>
          i.points === parseInt(state.search) ||
          i.user.firstName.toLowerCase().includes(state.search.toLowerCase()) ||
          i.user.lastName.toLowerCase().includes(state.search.toLowerCase()) ||
          i.user.email.toLowerCase().includes(state.search.toLowerCase()) ||
          i.user.phone.trim() === state.search.trim()
      )
    : data;

  return (
    <>
      <div className="card-block">
        <Loader isLoading={isUserGiftsLoading} />

        <DataTable
          title="Leader boards"
          subHeader
          subHeaderComponent={tHead}
          progressPending={isLoading || isUserGiftsLoading}
          progressComponent={<Loader />}
          columns={leaderBoardsColumns}
          data={filteredData}
        />
      </div>
      <div className="card-block" style={{ marginTop: 100 }}>
        <DataTable
          title={`${state.type} gifted users`}
          // subHeader
          // subHeaderComponent={tHead}
          progressPending={isGiftedUsersAnalyticLoading || isUserGiftsLoading}
          progressComponent={<Loader />}
          columns={winnerUsersColumn}
          data={giftedUsers}
        />
      </div>
      <Modal show={state.showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Send gift to user </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.sendGiftToUserError && (
            <Alert variant="danger">{state.sendGiftToUserError} </Alert>
          )}
          <Form.Select
            name="selected"
            aria-label="Default select example"
            defaultValue={state.selected}
            onChange={handleSelected}
          >
            <option>Choose</option>
            {gifts &&
              gifts.map((gift) => (
                <option key={`gift-option-${gift.id}`} value={gift.id}>
                  {gift.title} ({gift.quantity}) - {gift.type}
                </option>
              ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="success"
            disabled={
              !state.selected ||
              !state.userId ||
              isSendGiftToUserLoading ||
              state.sendGiftToUserError
            }
            onClick={handleSendGiftToUser}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
