import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Row, Col, Button, Alert, Form, Modal } from "react-bootstrap";
import {
  millerApi,
  useModeratoRandomChequeQuery,
  useModeratorUpdateChequeMutation,
} from "../../api/miller.api";
import ReactPanZoom from "./ReactPanZoom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { endOfWeek, parseISO, startOfWeek } from "date-fns";
import { formatTheDateYall } from "../../helper";

export const RandomCheque = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    point: 0,
    code: "",
    showModal: false,
    selectedReason: null,
    chequeDate: null,
  });

  const {
    isLoading,
    data,
    isError,
    refetch: fetchRandomCheque,
  } = useModeratoRandomChequeQuery();

  const [
    updateItem,
    {
      isLoading: isItemUpdateLoading,
      error: itemError,
      isSuccess: isItemSuccess,
    },
  ] = useModeratorUpdateChequeMutation();

  const handleUpdate = useCallback(
    async (body = {}) => {
      await updateItem(body);
      dispatch(millerApi.util.invalidateTags(["ModeratorCheque"]));
      fetchRandomCheque();
    },
    [dispatch, updateItem, fetchRandomCheque]
  );

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    switch (name) {
      case "point": {
        if (value > 0 && value <= 1000 && !isNaN(parseInt(value))) {
          setState((prevState) => ({
            ...prevState,
            [name]: parseInt(value),
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            [name]: null,
          }));
        }
        break;
      }
      default:
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    }
  }, []);

  useEffect(() => {
    fetchRandomCheque();
  }, [fetchRandomCheque]);

  useEffect(() => {
    if (isItemSuccess) {
      dispatch(millerApi.util.invalidateTags(["ModeratorCheque"]));
      fetchRandomCheque();
    }
  }, [isItemSuccess, dispatch, fetchRandomCheque]);

  const handleApprove = useCallback(async () => {
    if (!window.confirm(`Are you sure to set ${state.point} points?`) || !data)
      return;

    try {
      await updateItem({
        id: data.id,
        status: "approved",
        ...state,
        chequeDate: new Date(
          state.chequeDate.getTime() -
            state.chequeDate.getTimezoneOffset() * 60000
        ),
      });
      setState((prevState) => ({
        ...prevState,
        point: 0,
        code: "",
      }));
    } catch (error) {}
  }, [data, state, updateItem]);

  const handleReject = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      // showModal: !prevState.showModal,
      showModal: true,
    }));
    // let note = window.prompt("Enter reason?").trim();
    // if (note)
    // handleUpdate({ id: data.id, status: "rejected", note });
  }, []);

  const handleReason = useCallback((e) => {
    const { value, name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  if (isError)
    return (
      <Row>
        <Col md={{ offset: 3, span: 6 }}>
          <h1>Something went wrong, can't fetch receipt.</h1>
        </Col>
      </Row>
    );

  const startDate = data
    ? startOfWeek(formatTheDateYall(data.createdAt), { weekStartsOn: 1 })
    : startOfWeek(new Date(), { weekStartsOn: 1 });
  const endDate = data
    ? endOfWeek(formatTheDateYall(data.createdAt), { weekStartsOn: 1 })
    : endOfWeek(new Date(), { weekStartsOn: 1 });

  return (
    <>
      <Row>
        <Col md={{ offset: 3, span: 6 }}>
          {isLoading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "25px 0",
              }}
            >
              Loading...
            </div>
          )}

          {itemError && itemError.message && (
            <Alert variant="warning">{itemError.message}</Alert>
          )}

          {data ? (
            <>
              <Card border="secondary">
                <div
                  style={{
                    width: "100%",
                    height: 400,
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <ReactPanZoom alt="cool image" image={data.url} />
                </div>
                ,
                {/* <Card.Img
                  variant="top"
                  src={data.url}
                  // style={{ maxWidth: 200 }}
                /> */}
                <Card.Body>
                  {itemError && itemError.data && itemError.data.message && (
                    <Alert variant="danger"> {itemError.data.message} </Alert>
                  )}
                  <Form.Group className="mb-3" controlId="cheque.user">
                    <p style={{ color: "yellowgreen" }}>
                      You have 1 minute to review this receipt
                    </p>{" "}
                    <br />
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "rgb(238 238 238)",
                        padding: "8px",
                      }}
                    >
                      {data.user.firstName} - {data.user.lastName} -{" "}
                      {data.user.email}
                    </p>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="cheque.code"
                    autocomplete="off"
                  >
                    <Form.Label>Receipt ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="code"
                      autoComplete="off"
                      value={state.code}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="cheque.point"
                    autocomplete="off"
                  >
                    <Form.Label>Point</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      name="point"
                      value={state.point}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="cheque.point"
                    autocomplete="off"
                  >
                    <Form.Label>Date</Form.Label>
                    <DatePicker
                      utcOffset={0}
                      showIcon
                      selected={state.chequeDate}
                      onChange={(chequeDate) =>
                        setState((prevState) => ({
                          ...prevState,
                          chequeDate,
                        }))
                      }
                      minDate={startDate}
                      maxDate={endDate}
                      dateFormat="yyyy/MM/dd"
                    />
                  </Form.Group>
                </Card.Body>
                <Card.Footer>
                  <Button
                    className="block-user-btn"
                    style={{
                      border: "0",
                      fontSize: "14px",
                      margin: "0 5px",
                    }}
                    onClick={handleReject}
                    disabled={isItemUpdateLoading}
                  >
                    Reject
                  </Button>

                  <Button
                    className="hold-btn"
                    style={{
                      border: "0",
                      fontSize: "14px",
                      margin: "0 5px",
                    }}
                    onClick={() => {
                      let note = window.prompt("Enter reason?");
                      if (note)
                        handleUpdate({ id: data.id, status: "on_hold", note });
                    }}
                    disabled={isItemUpdateLoading}
                  >
                    Hold
                  </Button>

                  <Button
                    className="approve-btn float-end"
                    style={{
                      border: "0",
                      fontSize: "12px",
                      margin: "0 5px",
                    }}
                    onClick={handleApprove}
                    disabled={
                      isItemUpdateLoading ||
                      !state.code ||
                      !state.point ||
                      !state.chequeDate
                    }
                  >
                    Approve
                  </Button>
                </Card.Footer>
              </Card>
            </>
          ) : (
            <Card border="secondary">
              <Card.Header>
                {isLoading
                  ? "Loading..."
                  : "Receipt isn't available, please click refresh to get new one."}
              </Card.Header>
              <Card.Footer>
                <Button
                  className="approve-btn float-end"
                  style={{
                    border: "0",
                    fontSize: "12px",
                    margin: "0 5px",
                  }}
                  disabled={isLoading}
                  onClick={() => fetchRandomCheque()}
                >
                  Refresh
                </Button>
              </Card.Footer>
            </Card>
          )}
        </Col>
      </Row>

      <Modal
        show={state.showModal}
        onHide={() => {
          setState((prevState) => ({
            ...prevState,
            selectedReason: null,
            showModal: false,
          }));
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Reject user receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            name="selectedReason"
            aria-label="Default select example"
            defaultValue={state.selectedReason}
            onChange={handleReason}
          >
            <option>Choose reason</option>
            <option value="ატვირთული ფოტო არ შეესაბამება მოთხოვნებს.">
              ატვირთული ფოტო არ შეესაბამება მოთხოვნებს.
            </option>
            <option value="დუბლიკატი, სალარო ამონაწერი უკვე არის სისტემაში ატვირთული.">
              დუბლიკატი, სალარო ამონაწერი უკვე არის სისტემაში ატვირთული.
            </option>
            <option value="სალარო ამონაწერზე არ იკითხება მონაცემები, გთხოვთ ატვირთოთ ხელახლა.">
              სალარო ამონაწერზე არ იკითხება მონაცემები, გთხოვთ ატვირთოთ ხელახლა.
            </option>
            <option value="სალარო ამონაწერზე არსებული თარიღი არ ემთხვევა კამპანიის პერიოდს.  *გაითვალისწინე - სალარო ამონაწერის თარიღი უნდა ემთხვეოდეს მიმდინარე კვირის თარიღებს. თუ ატვირთული ამონაწერი გასული კვირით თარიღდება ამონაწერი იბლოკება.">
              სალარო ამონაწერზე არსებული თარიღი არ ემთხვევა კამპანიის პერიოდს.
              *გაითვალისწინე - სალარო ამონაწერის თარიღი უნდა ემთხვეოდეს
              მიმდინარე კვირის თარიღებს. თუ ატვირთული ამონაწერი გასული კვირით
              თარიღდება ამონაწერი იბლოკება.
            </option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              setState((prevState) => ({
                showModal: false,
              }))
            }
          >
            Close
          </Button>
          <Button
            variant="success"
            disabled={!state.selectedReason}
            onClick={() => {
              handleUpdate({
                id: data.id,
                status: "rejected",
                note: state.selectedReason,
              });
              setState((prevState) => ({
                ...prevState,
                selectedReason: null,
                showModal: false,
              }));
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
