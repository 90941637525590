import React, { useEffect } from "react";
import { LandingPageContainer } from "../../../Components/LandingPage/LandingPageContainer";
import { useFormik } from "formik";
import { useUpdateMeMutation } from "../../../api/miller.api";
import { ProfileUpdateSchema } from "./validation";
import { ProfileEdit } from "./ProfileEdit";
import { Alert } from "react-bootstrap";

export const ChangePasswordContainer = () => {
  const [
    updateMe,
    {
      isLoading,
      // isError,
      isSuccess,
    },
  ] = useUpdateMeMutation();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: "",
    },
    validationSchema: ProfileUpdateSchema,
    onSubmit: async (values, methods) => {
      await updateMe(values);
      methods.resetForm();
    },
  });

  return (
    <>
      <LandingPageContainer>
        <div className="user-profile-wrapper">
          <div className="user-profile-content">
            <h2>პროფილის რედაქტირება</h2>

            <div className="profile-edit">
              <div className="input-item password-area">
                {isSuccess && (
                  <Alert
                    variant="success"
                    dismissible
                    style={{ width: "100%", marginBottom: "35px" }}
                  >
                    პაროლი წარმატებით განახლდა.
                  </Alert>
                )}
                <h3 className="title-item-h3">პაროლის შეცვლა</h3>

                <input
                  type="password"
                  placeholder="ახალი პაროლი"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur("password")}
                  className={
                    formik.errors.password && formik.touched.password
                      ? "is-invalid"
                      : ""
                  }
                />
                <br />
                <input
                  type="password"
                  placeholder="გაიმეორე პაროლი"
                  name="confirm"
                  value={formik.values.confirm}
                  onChange={formik.handleChange("confirm")}
                  onBlur={formik.handleBlur("confirm")}
                  className={
                    formik.errors.confirm && formik.touched.confirm
                      ? "is-invalid"
                      : ""
                  }
                />
                <br />
                <button
                  type="button"
                  disabled={isLoading || !formik.isValid}
                  onClick={() => formik.submitForm()}
                >
                  შენახვა
                </button>
              </div>
              <div className="right-side">
                <ProfileEdit />
              </div>
            </div>
          </div>
        </div>
      </LandingPageContainer>
    </>
  );
};
