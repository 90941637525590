import React, { useState } from "react";

export const ProfileNotification = ({ note = "" }) => {
  const [message, setMessage] = useState(note);
  return (
    message && (
      <>
        <div className="notification">
          <div className="left">
            <svg
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.5 5.66667C14.5464 5.66667 5.66667 14.5464 5.66667 25.5C5.66667 36.4536 14.5464 45.3333 25.5 45.3333C36.4536 45.3333 45.3333 36.4536 45.3333 25.5C45.3333 14.5464 36.4536 5.66667 25.5 5.66667ZM0 25.5C0 11.4167 11.4167 0 25.5 0C39.5833 0 51 11.4167 51 25.5C51 39.5833 39.5833 51 25.5 51C11.4167 51 0 39.5833 0 25.5Z"
                fill="#EFDEAF"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.5003 11.1781C27.2366 11.1781 28.6441 12.4622 28.6441 14.0462V26.4744C28.6441 28.0584 27.2366 29.3425 25.5003 29.3425C23.764 29.3425 22.3564 28.0584 22.3564 26.4744V14.0462C22.3564 12.4622 23.764 11.1781 25.5003 11.1781Z"
                fill="#EFDEAF"
              />
              <path
                d="M25.4997 39.8219C27.6218 39.8219 29.3422 38.1016 29.3422 35.9794C29.3422 33.8573 27.6218 32.137 25.4997 32.137C23.3776 32.137 21.6572 33.8573 21.6572 35.9794C21.6572 38.1016 23.3776 39.8219 25.4997 39.8219Z"
                fill="#EFDEAF"
              />
            </svg>
            <p style={{ lineHeight: "20px" }}>{message}</p>
          </div>
          <div className="close" onClick={() => setMessage("")}>
            დახურვა
          </div>
        </div>
      </>
    )
  );
};
