import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVerifyEmailQuery } from "../api/miller.api";
import { Alert } from "react-bootstrap";
import Header from "../Components/LandingPage/Header";
import Footer from "../Components/LandingPage/Footer";

export const VerificationContainer = () => {
  const navigate = useNavigate();
  const { code = null } = useParams();
  const [visibleSignIn, setVisibleSignIn] = useState();

  const {
    // isLoading,
    error,
  } = useVerifyEmailQuery(code);

  if (!code) {
    return navigate("/");
  }

  return (
    <>
      <Header
        visibleSignIn={visibleSignIn}
        setVisibleSignIn={setVisibleSignIn}
      />
      <div className="main-area general-notificaiton">
        <div className="main-area-container">
          <div className="main-area-in">
            <Alert variant={error ? "danger" : "primary"}>
              {error ? (
                "დაფიქსირდა შეცდომა"
              ) : (
                <>
                  ვერიფიკაცია წარმატებით დასრულდა. გთხოვთ გაიროთ{" "}
                  <button
                    type="button"
                    style={{
                      background: "none",
                      border: "none",
                      padding: 0,
                      color: "#fff",
                      textDecoration: "underline",
                      fontStyle: "italic",
                    }}
                    onClick={() => setVisibleSignIn(true)}
                  >
                    ავტორიზაცია
                  </button>
                  .
                </>
              )}
            </Alert>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
