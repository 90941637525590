import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import i18n from "./i18n";
import { setBaseUrls } from "./helper/baseUrl";

import App from "./App";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { millerApi } from "./api/miller.api";

let xhrConfig;

// function setConsole(server) {
//   if (server === "production") {
//     console.log = () => {};
//     console.warn = () => {};
//     console.error = () => {};
//   }
// }

const fetchConfig = () =>
  new Promise((resolve, reject) => {
    xhrConfig = new XMLHttpRequest();
    xhrConfig.open("GET", "/config.js", true);
    xhrConfig.setRequestHeader("Cache-Control", "no-cache");
    xhrConfig.onload = resolve;
    xhrConfig.onerror = reject; // () => reject(xhrConfig.statusText); //  console.error(xhrConfig.statusText);
    xhrConfig.send(null);
  });

function onConfigResult(config) {
  // set base properties
  setBaseUrls({
    baseUrl: config.baseUrlForApi,
    staticBaseUrl: config.staticBaseUrl,
    authorizationUrl: config.authorizationUrl,
    lbUrl: config.baseLbApiUrl,
    server: config.server,
  });
  // setConsole(config.server);
}

function requestOnLoad() {
  if (xhrConfig.readyState === 4 && xhrConfig.status === 200) {
    // let serverConfig = JSON.parse(xhrConfig.responseText);
    onConfigResult(window.config);

    ReactDOM.render(
      <React.StrictMode>
        <ApiProvider api={millerApi}>
          <I18nextProvider i18n={i18n}>
            <Router>
              <App />
            </Router>
          </I18nextProvider>
        </ApiProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  }
}

fetchConfig().then(requestOnLoad).catch();
