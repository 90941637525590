import React, { useCallback, useState } from "react";
import FullList from "./FullList";
import { useGetClientAnalyticsQuery } from "../../api/miller.api";

export const LeaderBoardContainer = () => {
  const [state, setState] = useState({
    showFullList: false,
  });

  const {
    // isLoading: isWeeklyAnalyticLoading,
    // isError: isWeeklyAnalyTicError,
    data: weeklyAnalyticData,
    // refetch: fetchAnalytics,
  } = useGetClientAnalyticsQuery({ type: "weekly", v: 1, includeDate: true });

  const {
    // isLoading: iscampaigningLoading,
    // isError: iscampaigningError,
    data: campaigningData,
    // refetch: fetchCampaigningAnalytics,
  } = useGetClientAnalyticsQuery({ type: "campaign", v: 1 });

  const showList = useCallback(
    (showFullList = true) => {
      setState((prevState) => ({
        ...prevState,
        showFullList,
      }));
    },
    [setState]
  );

  return (
    <>
      <div className="leaderboards-container">
        <div className="leaderbords">
          <div className="leaderboard-item">
            <div className="leaderboard-header">
              <h3>კვირის ლიდერბორდი</h3>
              <p>
 
                <span>
                  {weeklyAnalyticData &&
                    `${weeklyAnalyticData?.startDate.slice(0, 5)} / `}
                  {weeklyAnalyticData &&
                    weeklyAnalyticData?.endDate.slice(0, 5)}{" "}
                  {weeklyAnalyticData?.endDate.slice(6, 10)}
                </span>
              </p>
            </div>
            <div className="leaderboard-item-content">
              <div className="user-data-item-list">
                {weeklyAnalyticData &&
                  weeklyAnalyticData.data.map((item, index) => (
                    <div className="user-data-item" key={item._id}>
                      <div className="index">{index + 1}</div>
                      <div className="user-info">
                        {item.user.firstName[0]}**** {item.user.lastName[0]}
                        *****
                      </div>

                      <div className="user-info">{item.points}</div>
                      <div className="user-info">-</div>
                    </div>
                  ))}
                  {console.log('weeklyAnalyticData',weeklyAnalyticData?.data?.length)}
                  {
                    weeklyAnalyticData?.data?.length < 1 ? <p style={{ color: "white", marginTop: 50 }}>მონაცემები მალე დაემატება </p> : null
                  }
              </div>

          
                <div className="see-history">
                  {/* <div className="full-button">
                    სრულად ნახვა
                  </div> */}
                  <div className="see-history-btn" onClick={showList}>
                    ისტორიის ნახვა
                  </div>
            </div>
            </div>

          
          </div>
          {/* <div className="leaderboard-item full">
            <div className="leaderboard-header">
              <h3>კამპანიის ლიდერბორდი</h3>
              <p>
                <span>10.07 / 10.09 2023</span>
              </p>
            </div>
            <div className="leaderboard-item-content">
              <div className="user-data-item-list">
                {campaigningData &&
                  campaigningData.data.map((item, index) => (
                    <div
                      className="user-data-item"
                      key={`campaigning-${item._id}`}
                    >
                      <div className="index">{index + 1}</div>
                      <div className="user-info">
                        {item.user.firstName[0]}**** {item.user.lastName[0]}
                        *****
                      </div>
                      <div className="user-info">{item.points}</div>
                      <div className="user-info">-</div>
                    </div>
                  ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {state.showFullList ? <FullList onListHide={showList} /> : null}
    </>
  );
};
