import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { SignInValidation } from "./validation";
import { useSignInMutation } from "../../api/miller.api";
import { Alert } from "react-bootstrap";

export const SignInModal = ({
  visible,
  setVisible,
  setSignUpVisible,
  setVisibleForgot,
}) => {
  const navigate = useNavigate();

  const [fetchSignIn, { isLoading, error }] = useSignInMutation();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignInValidation,
    onSubmit: async (values, methods) => {
      const { data } = await fetchSignIn(values);
      localStorage.setItem("access_token", data.access_token);
      setVisible(false);

      navigate("/profile");
    },
  });

  return visible ? (
    <div className="modal-item-wrapper auth-modal">
      <div className="modal-item-content">
        <div className="logo">
          <img src="/images/logomain.svg" />
        </div>
        <div className="close-modal" onClick={() => setVisible(false)}>
          <svg width="57" height="58" viewBox="0 0 57 58" fill="none">
            <g filter="url(#filter0_d_57_2)">
              <rect
                x="53"
                y="50"
                width="49"
                height="50"
                rx="16"
                transform="rotate(180 53 50)"
                fill="#D0B478"
              />
            </g>
            <path
              d="M22 17L37 32M37 17L22 32"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
            />
            <defs>
              <filter
                id="filter0_d_57_2"
                x="0"
                y="0"
                width="57"
                height="58"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_57_2"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_57_2"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="form-item" style={{ paddingBottom: "20px" }}>
          <h3>ავტორიზაცია</h3>

          <div className="input-item">
            <input
              type="email"
              placeholder="ელ. ფოსტა"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              className={
                formik.errors.email && formik.touched.email
                  ? "invalid-item"
                  : ""
              }
            />
          </div>
          <div className="input-item">
            <input
              type="password"
              placeholder="პაროლი"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange("password")}
              onBlur={formik.handleBlur("password")}
              className={
                formik.errors.password && formik.touched.password
                  ? "invalid-item"
                  : ""
              }
            />
          </div>
          <div className="res-pass-row">
            <div
              href="javascript:void(0)"
              onClick={() => {
                setSignUpVisible(false);
                setVisible(false);
                setVisibleForgot(true);
              }}
            >
              დაგავიწყდათ პაროლი?
            </div>
          </div>
          {error && error.data && error.data.message && (
            <Alert variant="danger" dismissible>
              {error.data.error === "Unauthorized"
                ? "მითითებული მონაცემებით მომხმარებელი არ არსებობს."
                : error.data.message}
            </Alert>
          )}
          <div className="action-btns">
            <div
              className={`action-btn ${
                isLoading || formik.isValid ? "disabled" : ""
              }`}
              onClick={() => formik.submitForm()}
            >
              {isLoading ? "Loading..." : "ავტორიზაცია"}
            </div>
            <div
              className="action-btn"
              onClick={() => {
                setSignUpVisible(true);
                setVisible(false);
              }}
            >
              რეგისტრაცია
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
