import React, { useState } from "react";
import { useUserChequesQuery } from "../../api/miller.api";
import { ProfileChequeItem } from "./ProfileChequeItem";

export const ProfileChequeList = ({ setCheckAmount }) => {
  const [state] = useState({
    search: "",
    skip: 0,
    take: 20,
  });
  const { isLoading, data } = useUserChequesQuery({
    search: state.search,
    skip: state.skip,
    take: state.take,
  });

  if (!isLoading && data?.length > 0) {
    setCheckAmount(data?.length);
  }

  return (
    <div className="receipt-list">
      {isLoading && (
        <h4
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            margin: "25px 0",
            color: "#fff",
          }}
        >
          Loading...
        </h4>
      )}
      {!isLoading &&
        data &&
        !!data.length &&
        data
          .toReversed()
          .map((cheque) => <ProfileChequeItem key={cheque.id} item={cheque} />)}
      {data && data.length >= 20 && (
        <div className="show-more-row">
          <button>მეტის ნახვა</button>
        </div>
      )}
    </div>
  );
};
