import React from "react";
import jwt_decode from "jwt-decode";
import { Header } from "../Components/dashboard/header";
import { LeaderBoardList } from "./leaderboard/LeaderBoardList";
import { useLocation } from "react-router-dom";
import { LeaderBoardListV2 } from "./leaderboard/LeaderBoardListV2";

export const LeaderBoards = () => {
  const location = useLocation();
  const user = jwt_decode(localStorage.getItem("access_token"));
  console.log(location);
  return (
    <>
      <div className="dashboard-wrapper">
        <Header />
        <div className="dashboard-content">
          {/**
           * create user Component
           */}
          {/**
           * moderator cheque
           */}
          {/**
           * Admin Cheque List
           */}
          {user &&
          ["admin"].includes(user.role) &&
          location.pathname !== "/dashboard/leader-boards/v2" ? (
            <LeaderBoardListV2 />
          ) : null}
          {user &&
          ["admin"].includes(user.role) &&
          location.pathname === "/dashboard/leader-boards/v2" ? (
            <LeaderBoardList />
          ) : null}
        </div>
      </div>
    </>
  );
};
