import React, { useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import {
  millerApi,
  useChequeUpdateMutation,
  useGetChequesQuery,
} from "../../api/miller.api";
import DataTable from "react-data-table-component";
import { Loader } from "../../Components/Loader";
import { Button } from "react-bootstrap";
import { formatInTimeZone, fromZonedTime } from "date-fns-tz";
import { format, add, parseISO } from "date-fns";

export const ChequeList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    search: "",
    skip: 0,
    take: 20,
  });

  const { isLoading, data } = useGetChequesQuery({
    search: state.search,
    skip: state.skip,
    take: state.take,
  });

  const [updateCheque, { isLoading: isChequeLoading }] =
    useChequeUpdateMutation();

  const handleChequeStatus = useCallback(
    async (data) => {
      let note = null;
      let allowAction = false;

      if (data && data.status === "rejected") {
        note =
          window.prompt("Enter reason").trim() ||
          "ატვირთული ფოტო არ შეესაბამება მოთხოვნებს.";
        allowAction = !!note;
        data.point = 0;
      } else if (data && data.status === "approved") {
        let point = parseInt(window.prompt("Enter points"));
        if (!isNaN(point) && point > 0 && point <= 1000) {
          data.point = point;
          allowAction = true;
        } else {
          window.alert(`Point ${point} is not correct`);
        }
      } else {
        allowAction = window.confirm(
          `Are you sure to  ${
            data.status === "inprogress"
              ? "Hold"
              : data.status === "rejected"
              ? "Reject"
              : "Approve"
          }?`
        );
      }

      if (allowAction) {
        await updateCheque({ ...data, note });
        dispatch(millerApi.util.invalidateTags(["Cheque"]));
      }
    },
    [updateCheque, dispatch]
  );

  const columns = [
    {
      name: "Point",
      selector: (row) => row.point,
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => `${row.user ? row.user.firstName : ""}`,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => `${row.user ? row.user.lastName : ""}`,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => `${row.user ? row.user.email : ""}`,
      sortable: true,
    },
    {
      name: "Comment",
      selector: (row) => <span>{row.note}</span>,
      sortable: true,
    },
    {
      name: "Receipt",
      selector: (row) => (
        <div className="item-in">
          {row.url ? (
            <>
              <img src={row.url} alt="" />
              <div className="receipt-tooltip">
                <img src={row.url} alt="" />
                <a href={row.url} target="_blank" rel="noreferrer">
                  სრულად ნახვა
                </a>
              </div>
            </>
          ) : null}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Receipt ID",
      selector: (row) => row.code,

      sortable: true,
    },
    {
      name: "Uploaded At",
      // selector: (row) => formatTheDateYall(row.createdAt),
      cell: (row) => {
        return formatInTimeZone(row.createdAt, "UTC", "yyyy-MM-dd HH:mm:ss");
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "approved" ? (
          <span style={{ color: "#00a558", fontWeight: "bold" }}>
            {row.status === "approved" ? "Verified" : ""}
          </span>
        ) : row.status === "inprogress" ? (
          <span style={{ color: "orange", fontWeight: "bold" }}>
            {row.status === "inprogress" ? "Inprogress" : ""}
          </span>
        ) : row.status === "on_hold" ? (
          <span style={{ color: "orange", fontWeight: "bold" }}>
            {row.status === "on_hold" ? "On Hold" : ""}
          </span>
        ) : row.status === "rejected" ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            {row.status === "rejected" ? "Rejected" : ""}
          </span>
        ) : null,

      sortable: true,
    },

    {
      name: "actions",
      selector: (row) => (
        <>
          {row.status !== "rejected" ? (
            <Button
              className="block-user-btn"
              style={{
                border: "0",
                fontSize: "14px",
                margin: "0 5px",
              }}
              onClick={() =>
                handleChequeStatus({ id: row.id, status: "rejected" })
              }
              disabled={isChequeLoading}
            >
              Reject
            </Button>
          ) : null}
          {row.status !== "inprogress" && row.status !== "on_hold" ? (
            <Button
              className="hold-btn"
              style={{
                border: "0",
                fontSize: "14px",
                margin: "0 5px",
              }}
              onClick={() =>
                handleChequeStatus({ id: row.id, status: "inprogress" })
              }
              disabled={isChequeLoading}
            >
              Hold
            </Button>
          ) : null}

          {row.status !== "approved" ? (
            <Button
              className="approve-btn"
              style={{
                border: "0",
                fontSize: "12px",
                margin: "0 5px",
              }}
              onClick={() => {
                handleChequeStatus({ id: row.id, status: "approved" });
              }}
              disabled={isChequeLoading}
            >
              Approve
            </Button>
          ) : null}
        </>
      ),
      sortable: false,
    },
  ];

  const loadItems = useCallback(
    async (perPage) => {
      if (isLoading) return;
      setState((prevState) => ({
        ...prevState,
        skip: (perPage - 1) * prevState.take,
        // skip:
        //   perPage > 1
        //     ? prevState.skip + prevState.take
        //     : prevState.skip - prevState.take,
      }));
      dispatch(millerApi.util.invalidateTags(["User"]));
    },
    [isLoading, dispatch]
  );

  // Transform data

  const handleChangeSearch = useDebouncedCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, 1000);

  const tHead = useMemo(() => {
    return (
      <>
        <p
          style={{
            fontSize: "20px",
            marginBottom: "15px",
            marginRight: "20px",
          }}
        >
          Verified:{" "}
          <span style={{ color: "green", marginRight: "10px" }}>
            {data?.approved}
          </span>{" "}
          On Hold:{" "}
          <span style={{ color: "#fd7e14", marginRight: "10px" }}>
            {data?.holded}
          </span>{" "}
          Inpogress:{" "}
          <span style={{ color: "#fd7e14", marginRight: "10px" }}>
            {data?.inProgress}
          </span>{" "}
          Rejected:
          <span style={{ color: "red", marginRight: "10px" }}>
            {" "}
            {data?.rejected}
          </span>
        </p>
        <div className="filter-side">
          <input
            placeholder="For search name, lastname, id, email..."
            onChange={handleChangeSearch}
            name="search"
          />
        </div>
      </>
    );
  }, [handleChangeSearch, data]);

  return (
    <div className="card-block receipt-table">
      <DataTable
        title="All receipts"
        subHeader
        subHeaderComponent={tHead}
        progressPending={isLoading}
        progressComponent={<Loader />}
        columns={columns}
        data={data && data.data}
        //
        pagination
        paginationServer
        paginationTotalRows={
          state.skip + state.take + (data && data.data.length < 20 ? 0 : 1)
        }
        paginationPerPage={20}
        paginationComponentOptions={{
          noRowsPerPage: false,
          selectAllRowsItem: false,
          rowsPerPageText: "",
        }}
        paginationIconFirstPage={null}
        paginationIconLastPage={null}
        onChangePage={loadItems}
      />
      {isLoading ? <h3 className="loading-item">Loading...</h3> : null}
    </div>
  );
};
