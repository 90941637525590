import React, { useState, useCallback, useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { Loader } from "../../../Components/Loader";
import { millerApi, useModeratorChequeQuery } from "../../../api/miller.api";
import { RandomCheque } from "../../../Components/moderator/RandomCheque";

export const ChequeContainer = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    search: "",
    skip: 0,
    take: 20,
  });
  const { isLoading, data } = useModeratorChequeQuery({
    search: state.search,
    skip: state.skip,
    take: state.take,
  });

  // Transform data

  const columns = [
    {
      name: "Point",
      selector: (row) => row.point,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.status === "approved" ? (
          <span style={{ color: "#00a558", fontWeight: "bold" }}>
            {row.status === "approved" ? "Verified" : ""}
          </span>
        ) : row.status === "inprogress" ? (
          <span style={{ color: "orange", fontWeight: "bold" }}>
            {row.status === "inprogress" ? "Inprogress" : ""}
          </span>
        ) : row.status === "rejected" ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            {row.status === "rejected" ? "Rejected" : ""}
          </span>
        ) : row.status === "on_hold" ? (
          <span style={{ color: "orange", fontWeight: "bold" }}>
            {row.status === "on_hold" ? "OnHold" : ""}
          </span>
        ) : null,

      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.createdAt,
      sortable: true,
    },
  ];

  const loadItems = useCallback(
    async (perPage) => {
      setState((prevState) => ({
        ...prevState,
        skip:
          perPage > 1
            ? prevState.skip + prevState.take
            : prevState.skip - prevState.take,
      }));
      dispatch(millerApi.util.invalidateTags(["ModeratorCheque"]));
    },
    [dispatch]
  );

  // Transform data

  const handleChangeSearch = useDebouncedCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, 1000);

  const tHead = useMemo(() => {
    return (
      <>
        <div className="filter-side">
          <input
            placeholder="For search name, lastname, id, email..."
            onChange={handleChangeSearch}
            name="search"
            // value={state.search}
          />
        </div>
      </>
    );
  }, [handleChangeSearch]);
  return (
    <>
      <div className="card-block">
        <RandomCheque />

        <br />
        <DataTable
          title="All Receipts"
          subHeader
          subHeaderComponent={tHead}
          progressPending={isLoading}
          progressComponent={<Loader />}
          columns={columns}
          data={data}
          //
          pagination
          paginationServer
          paginationTotalRows={
            state.skip + state.take + (data && data.length < 20 ? 0 : 1)
          }
          paginationPerPage={20}
          paginationComponentOptions={{
            noRowsPerPage: false,
            selectAllRowsItem: false,
            rowsPerPageText: "",
          }}
          paginationIconFirstPage={null}
          paginationIconLastPage={null}
          onChangePage={loadItems}
        />
        {isLoading ? <h3 className="loading-item">Loading...</h3> : null}
      </div>
    </>
  );
};
