import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useResetPasswordMutation,
  useLazyValidateCodeQuery,
} from "../api/miller.api";
import { Alert, Button, Col } from "react-bootstrap";
import Header from "../Components/LandingPage/Header";
import Footer from "../Components/LandingPage/Footer";
import { useFormik } from "formik";
import { ResetPasswordValidation } from "./validation";

export const PasswordResetContainer = () => {
  const navigate = useNavigate();
  const { code = null } = useParams();
  const [visibleSignIn, setVisibleSignIn] = useState();

  const [
    validate,
    {
      // isLoading,
      error,
    },
  ] = useLazyValidateCodeQuery();

  const [fetchData, { isLoading: isResetPasswordLoading, isSuccess }] =
    useResetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: ResetPasswordValidation,
    onSubmit: async (values, methods) => {
      const { data } = await fetchData({ code, password: values.password });
      //   setVisible(false);
      methods.resetForm();
    },
  });

  useEffect(() => {
    if (code) {
      validate({ code });
    }
  }, [code, validate]);

  //   if (!code) {
  //     return navigate("/");
  //   }

  console.log(code);

  return (
    <>
      <Header
        visibleSignIn={visibleSignIn}
        setVisibleSignIn={setVisibleSignIn}
      />
      <div className="main-area general-notificaiton">
        <div className="main-area-container">
          <div className="main-area-in">
            {!isSuccess && (
              <Alert variant={error ? "danger" : "primary"}>
                {error ? (
                  "დაფიქსირდა შეცდომა"
                ) : (
                  <div className="res-pass-item">
                    <Col md={12}>
                      <input
                        type="password"
                        placeholder="ახალი პაროლი"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange("password")}
                        onBlur={formik.handleBlur("password")}
                        className={
                          formik.errors.password && formik.touched.password
                            ? "invalid-item"
                            : ""
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <input
                        type="password"
                        placeholder="გაიმეორე პაროლი"
                        name="confirm_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange("confirm_password")}
                        onBlur={formik.handleBlur("confirm_password")}
                        className={
                          formik.errors.confirm_password &&
                          formik.touched.confirm_password
                            ? "invalid-item"
                            : ""
                        }
                      />
                    </Col>
                    <div className="input-item">
                      <button
                        className="btn btn-primary"
                        disabled={
                          !formik.values.password ||
                          !formik.values.confirm_password ||
                          formik.values.password !==
                            formik.values.confirm_password
                        }
                        onClick={() => formik.submitForm()}
                      >
                        შენახვა
                      </button>
                    </div>
                  </div>
                )}
              </Alert>
            )}

            {isSuccess && (
              <Alert variant="primary">პაროლი წარმატებით შეიცვალა</Alert>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
