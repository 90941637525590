import { useEffect, useState } from "react";
import { useGetClientWinnersAnalyticsQuery } from "../../api/miller.api";

const FullList = ({ onListHide }) => {
  const [week, setWeek] = useState("");
  const [showWeek, setShowWeek] = useState(false);

  const { data, isSuccess } = useGetClientWinnersAnalyticsQuery();

  useEffect(() => {
    if (isSuccess && data && data.length) {
      setWeek({
        ...data[0],
        title: data[0].type === "weekly" ? "1 კვირა" : "კამპანია",
      });
      // console.log(data[0]);
    }
  }, [isSuccess, data]);
  return (
    <div className="custom-modal-item-wrapper">
      <div className="custom-modal-item-content">
        <div className="close-btn" onClick={() => onListHide(false)}>
          <svg width="57" height="58" viewBox="0 0 57 58" fill="none">
            <g filter="url(#filter0_d_57_2)">
              <rect
                x="53"
                y="50"
                width="49"
                height="50"
                rx="16"
                transform="rotate(180 53 50)"
                fill="#D0B478"
              />
            </g>
            <path
              d="M22 17L37 32M37 17L22 32"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
            />
            <defs>
              <filter
                id="filter0_d_57_2"
                x="0"
                y="0"
                width="57"
                height="58"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_57_2"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_57_2"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="leaderboard-item">
          <div className="leaderboard-header">
            <h3>კვირის ლიდერბორდი</h3>
            {week ? (
              <div className="choose-box">
                <div className="dt">
                  {week && `${week?.startDate} / ${week?.endDate} `}
                </div>

                <div className="dr-item">
                  <div
                    className="active-value"
                    onClick={() => setShowWeek(true)}
                  >
                    {week ? week.title : ""}
                    <svg
                      width="15"
                      height="9"
                      viewBox="0 0 15 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 1.5L7.5 7.5L1.5 1.5"
                        stroke="#D0B478"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  {showWeek ? (
                    <div className="dr-items">
                      {data &&
                        data.map((week, index) => (
                          <div
                            key={`winner-${week._id}`}
                            className="item"
                            onClick={() => {
                              setWeek({
                                ...week,
                                title: `${
                                  week.type === "weekly" ? index + 1 : ""
                                } ${
                                  week.type === "weekly" ? "კვირა" : "კამპანია"
                                }`,
                              });
                              setShowWeek(false);
                            }}
                          >
                            {week.type === "weekly" ? index + 1 : ""}
                            {week.type === "weekly" ? "კვირა" : "კამპანია"}
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          <div className="leaderboard-item-content">
            <div className="user-data-item-list">
              {(week &&
                week.winners.map((winner, index) => (
                  <div className="user-data-item">
                    <div className="index">{index + 1}</div>
                    <div className="user-info">
                      {winner.firstName[0]}**** {winner.lastName[0]}*****
                    </div>
                    <div className="user-info">{winner.points}</div>
                    <div className="user-info" title={winner.gift && winner.gift.title}>
                      {winner.gift && winner.gift.title}
                      {winner.finalGift && winner.finalGift.title}
                    </div>
                  </div>
                ))) || (
                <p style={{ color: "white" }}>
                  ამჟამად მონაცემები ვერ მოიძებნა
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FullList;
