import { useState } from "react";
import Footer from "../Components/LandingPage/Footer";
import Header from "../Components/LandingPage/Header";

const Thank = () => {
  const [visibleSignIn, setVisibleSignIn] = useState(false);
  return (
    <>
      <Header
        visibleSignIn={visibleSignIn}
        setVisibleSignIn={setVisibleSignIn}
      />
      <div className="thankyou-wrapper">
        <h3>
          მადლობა! რეგისტრაციის დასასრულებლად გთხოვთ, შეამოწმოთ ელ. ფოსტა და
          გააქტიუროთ ანგარიში.
          <br /> <br />
          <p style={{color: "#ff3c3c"}}>
            თუ ვერ მიიღეთ ვერიფიკაციის ბმული, გთხოვთ, გადაამოწმეთ Spam
            განყოფილება.
          </p>
        </h3>
      </div>
      <Footer />
    </>
  );
};
export default Thank;
