import { Link, useLocation } from "react-router-dom";
import { SignUpModal } from "../SignModal";
import { SignInModal } from "../SignModal/SignIn";
import { useState } from "react";

const Footer = ({ visibleSignIn, setVisibleSignIn, visible, setVisible }) => {
  const location = useLocation();
 
  return (
    <>
    <div className="header-row footer-row">
      <div className="container-item">
        <div className="header">
          <div className="logo-item">
          <Link to="/" className="">
                <img src="/images/logomain.svg" />
              </Link>
          </div>
          <div className="left">
            <ul>
              <li>
                <Link
                  to="/"
                  className={`${location.pathname === "/" ? "active" : ""}`}
                >
                  მთავარი
                </Link>
              </li>
              <li>
                <Link
                  to="/mechanic"
                  className={`${
                    location.pathname === "/mechanic" ? "active" : ""
                  }`}
                >
                  გათამაშების მექანიკა
                </Link>
              </li>
            
              <li>
                <Link
                  to="/rules"
                  className={`${
                    location.pathname === "/rules" ? "active" : ""
                  }`}
                >
                  წესები და პირობები
                </Link>
              </li>
              <li>
                <Link
                  to="/winners"
                  className={`${
                    location.pathname === "/winners" ? "active" : ""
                  }`}
                >
                  გამარჯვებულები
                </Link>
              </li>
              <li>
              <div class="btn-item"    onClick={() => setVisible(true)}>რეგისტრაცია</div>
              </li>
            </ul>
          </div>
          <div className="right">
            <a
              href="https://www.facebook.com/millerbeergeorgia"
              target="_blank"
              rel="noreferrer"
            >
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 1C10.4029 1 1 10.4029 1 22C1 33.5971 10.4029 43 22 43C33.5971 43 43 33.5971 43 22C43 10.4029 33.5971 1 22 1Z" stroke="#D0B478" stroke-opacity="0.5" stroke-width="2" stroke-miterlimit="10"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.7039 14.204C25.4654 14.1782 26.2334 14.1976 26.995 14.1976C27.0853 14.1976 27.1821 14.1976 27.3112 14.1976V10.2222C26.9046 10.1835 26.4787 10.1254 26.0463 10.0996C25.2654 10.0673 24.4781 10.0286 23.6972 10.0415C22.4968 10.0673 21.3674 10.3577 20.38 11.0676C19.2442 11.8872 18.6247 13.0295 18.3859 14.3847C18.2891 14.9462 18.2632 15.5206 18.2503 16.0885C18.231 16.9791 18.2439 17.8697 18.2503 18.7667V19.1023H14.4492V23.5424H18.231V34.6942H22.8517V23.5553H26.6207C26.8143 22.0774 27.0014 20.6189 27.2014 19.0959C26.8981 19.0959 26.6271 19.0959 26.3561 19.0959C25.2848 19.1023 22.826 19.0959 22.826 19.0959C22.826 19.0959 22.8388 16.8952 22.8647 15.9401C22.8776 14.6429 23.6584 14.2428 24.7039 14.204Z" fill="white"/>
</svg>

            </a>
            <a
              href="https://www.instagram.com/millerbeer_georgia"
              target="_blank"
              rel="noreferrer"
            >
             <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.1406 1C10.5435 1 1.14062 10.4029 1.14062 22C1.14062 33.5971 10.5435 43 22.1406 43C33.7378 43 43.1407 33.5971 43.1407 22C43.1407 10.4029 33.7378 1 22.1406 1Z" stroke="#D0B478" stroke-opacity="0.5" stroke-width="2" stroke-miterlimit="10"/>
<path d="M33.3055 16.5014C33.1571 15.0364 32.5957 13.7457 31.505 12.7066C30.3627 11.616 28.9623 11.132 27.4263 11.0481C24.8126 10.9061 18.6043 10.8158 16.423 11.1707C13.9319 11.5773 12.2991 13.0293 11.6085 15.4882C11.0793 17.3791 11.1826 26.1043 11.4924 27.9759C11.9119 30.5315 13.4284 32.1772 15.9582 32.829C17.7136 33.2807 26.3357 33.2226 28.2718 32.9129C30.8338 32.4998 32.4795 30.9961 33.1442 28.4663C33.6605 26.5173 33.4798 18.2567 33.3055 16.5014ZM31.2598 27.6145C30.9629 29.557 29.6722 30.7638 27.7168 30.9768C25.9227 31.1704 17.9073 31.2801 16.2487 30.8154C14.6547 30.3702 13.7447 29.273 13.4801 27.6725C13.2284 26.143 13.1639 18.4955 13.4736 16.4497C13.764 14.5266 15.0548 13.3198 16.9973 13.1068C18.9657 12.8874 26.0582 12.8615 27.9491 13.1519C29.8852 13.4488 31.0919 14.7395 31.3049 16.695C31.505 18.5213 31.5566 25.6526 31.2598 27.6145ZM22.3667 16.34C19.2173 16.34 16.6617 18.8892 16.6617 22.0386C16.6617 25.1879 19.2109 27.7435 22.3602 27.7435C25.5096 27.7435 28.0652 25.1944 28.0652 22.045C28.0652 18.8956 25.5161 16.34 22.3667 16.34ZM22.328 25.73C20.2887 25.7107 18.6559 24.0456 18.6753 22.0063C18.6946 19.9669 20.3597 18.3342 22.399 18.3535C24.4383 18.3729 26.071 20.0379 26.0517 22.0773C26.0323 24.1102 24.3609 25.7429 22.328 25.73ZM29.627 16.1271C29.627 16.8628 29.0268 17.463 28.2911 17.4565C27.5554 17.4565 26.9552 16.8563 26.9617 16.1206C26.9617 15.3849 27.5619 14.7847 28.2976 14.7912C29.0333 14.7847 29.627 15.3849 29.627 16.1271Z" fill="#B51A8A"/>
<path d="M33.3055 16.5014C33.1571 15.0364 32.5957 13.7457 31.505 12.7066C30.3627 11.616 28.9623 11.132 27.4263 11.0481C24.8126 10.9061 18.6043 10.8158 16.423 11.1707C13.9319 11.5773 12.2991 13.0293 11.6085 15.4882C11.0793 17.3791 11.1826 26.1043 11.4924 27.9759C11.9119 30.5315 13.4284 32.1772 15.9582 32.829C17.7136 33.2807 26.3357 33.2226 28.2718 32.9129C30.8338 32.4998 32.4795 30.9961 33.1442 28.4663C33.6605 26.5173 33.4798 18.2567 33.3055 16.5014ZM31.2598 27.6145C30.9629 29.557 29.6722 30.7638 27.7168 30.9768C25.9227 31.1704 17.9073 31.2801 16.2487 30.8154C14.6547 30.3702 13.7447 29.273 13.4801 27.6725C13.2284 26.143 13.1639 18.4955 13.4736 16.4497C13.764 14.5266 15.0548 13.3198 16.9973 13.1068C18.9657 12.8874 26.0582 12.8615 27.9491 13.1519C29.8852 13.4488 31.0919 14.7395 31.3049 16.695C31.505 18.5213 31.5566 25.6526 31.2598 27.6145ZM22.3667 16.34C19.2173 16.34 16.6617 18.8892 16.6617 22.0386C16.6617 25.1879 19.2109 27.7435 22.3602 27.7435C25.5096 27.7435 28.0652 25.1944 28.0652 22.045C28.0652 18.8956 25.5161 16.34 22.3667 16.34ZM22.328 25.73C20.2887 25.7107 18.6559 24.0456 18.6753 22.0063C18.6946 19.9669 20.3597 18.3342 22.399 18.3535C24.4383 18.3729 26.071 20.0379 26.0517 22.0773C26.0323 24.1102 24.3609 25.7429 22.328 25.73ZM29.627 16.1271C29.627 16.8628 29.0268 17.463 28.2911 17.4565C27.5554 17.4565 26.9552 16.8563 26.9617 16.1206C26.9617 15.3849 27.5619 14.7847 28.2976 14.7912C29.0333 14.7847 29.627 15.3849 29.627 16.1271Z" fill="white"/>
</svg>

            </a>
            <a
              href="https://www.youtube.com/channel/UCXUJAsXPX-terJ86s1iEg_Q"
              target="_blank"
              rel="noreferrer"
            >
              <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.2813 1C10.6842 1 1.28125 10.4029 1.28125 22C1.28125 33.5971 10.6842 43 22.2813 43C33.8784 43 43.2812 33.5971 43.2812 22C43.2812 10.4029 33.8784 1 22.2813 1Z" stroke="#D0B478" stroke-opacity="0.5" stroke-width="2" stroke-miterlimit="10"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.5109 29.2861C12.6784 29.0151 12.117 28.4665 11.8136 27.6404C11.0779 25.6205 10.865 17.1791 12.2784 15.5141C12.7624 14.9462 13.3819 14.6106 14.1241 14.5332C17.9317 14.1202 29.703 14.1847 31.0583 14.6687C31.8521 14.9527 32.4135 15.4754 32.7233 16.2692C33.5235 18.3537 33.5559 25.9496 32.6201 27.9567C32.3684 28.4988 31.9553 28.886 31.4261 29.1635C30.0063 29.9121 15.3825 29.8992 13.5109 29.2861ZM19.5644 25.3301C21.8554 24.1426 24.1271 22.968 26.4246 21.7741C24.1206 20.5737 21.8554 19.3863 19.5644 18.1924C19.5644 20.5802 19.5644 22.9358 19.5644 25.3301Z" fill="white"/>
</svg>

            </a>
          </div>
        </div>
      </div>
      <p>Copyright © 2024 All Rights Reserved</p>
    </div>
 
</>
  );
};
export default Footer;
