import { useFormik } from "formik";
import { profileValidation } from "./profileValidation";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import {
  millerApi,
  useGetMeQuery,
  useUpdateMeMutation,
} from "../../../api/miller.api";
import { Loader } from "../../../Components/Loader";
import { useDispatch } from "react-redux";

export const ProfileEdit = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    data,
    // isError,
    isSuccess,
  } = useGetMeQuery();
  const [
    updateMe,
    {
      isLoading: isUserUpdateLoading,
      isSuccess: isUserUpdateSuccess,
      error: userUpdateError,
    },
  ] = useUpdateMeMutation();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",

      //
    },
    // validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: profileValidation,
    onSubmit: async (values, methods) => {
      try {
        await updateMe(values);
        // methods.resetForm();
        dispatch(millerApi.util.invalidateTags(["GET_ME"]));
      } catch (error) {}
    },
  });

  useEffect(() => {
    if (isSuccess && data) {
      formik.setValues({
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
      });
    }
  }, [isSuccess, data]);

  if (isLoading) return <Loader />;

  return (
    <>
      {isUserUpdateSuccess && (
        <Alert variant="success" dismissible>
          მონაცემები წარმატებით განახლდა.
        </Alert>
      )}

      <div className="form-item">
        <h3 className="title-item-h3">პირადი მონაცემების შეცვლა</h3>

        <div
          className={`input-item ${
            formik.touched.firstName && formik.errors.firstName
              ? "invalid-item"
              : ""
          }`}
        >
          <input
            type="text"
            placeholder="სახელი*"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange("firstName")}
            onBlur={formik.handleBlur("firstName")}
            className={
              formik.errors.firstName && formik.touched.firstName
                ? "is-invalid"
                : ""
            }
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <span
              style={{
                color: "red",
                marginTop: 5,
                display: "inline-block",
                fontSize: 12,
              }}
            >
              სახელი აუცილებელია
            </span>
          ) : null}
        </div>
        <div
          className={`input-item ${
            formik.touched.lastName && formik.errors.lastName
              ? "invalid-item"
              : ""
          }`}
        >
          <input
            type="text"
            placeholder="გვარი*"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange("lastName")}
            onBlur={formik.handleBlur("lastName")}
            className={
              formik.errors.lastName && formik.touched.lastName
                ? "is-invalid"
                : ""
            }
          />

          {formik.touched.lastName && formik.errors.lastName ? (
            <span
              style={{
                color: "red",
                marginTop: 5,
                display: "inline-block",
                fontSize: 12,
              }}
            >
              გვარი აუცილებელია
            </span>
          ) : null}
        </div>
        <div
          className={`input-item ${
            formik.touched.phone && formik.errors.phone ? "invalid-item" : ""
          }`}
        >
          <input
            type="text"
            placeholder="მობ. ნომერი*"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange("phone")}
            onBlur={formik.handleBlur("phone")}
            className={
              formik.errors.phone && formik.touched.phone ? "is-invalid" : ""
            }
          />
          {formik.touched.phone && formik.errors.phone ? (
            <span
              style={{
                color: "red",
                marginTop: 5,
                display: "inline-block",
                fontSize: 12,
              }}
            >
              ტელეფონი აუცილებელია
            </span>
          ) : null}
        </div>

        <div
          className={`input-item ${
            formik.touched.phone && formik.errors.phone ? "invalid-item" : ""
          }`}
        >
          <input
            type="email"
            placeholder="ელ. ფოსტა"
            name="email"
            disabled
            value={data && data.email}
          />
        </div>
        <div
          className={`input-item ${
            formik.touched.email && formik.errors.email ? "invalid-item" : ""
          }`}
        >
          <input
            type="text"
            placeholder="პირადი ნომერი"
            name="personalId"
            disabled
            value={data && data.personalId}
          />
        </div>
        <div className="input-item">
          <button
            type="button"
            disabled={!formik.isValid || isUserUpdateLoading}
            onClick={() => formik.submitForm()}
          >
            შენახვა
          </button>
        </div>
      </div>
    </>
  );
};
