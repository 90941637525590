import React, { useState, useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useGetWinnersQuery } from "../../api/miller.api";

import { Accordion } from "react-bootstrap";
import { Loader } from "../../Components/Loader";
import DataTable from "react-data-table-component";

export const WinnerList = () => {
  const [state, setState] = useState({
    search: "",
    skip: 0,
    take: 20,
  });
  const { isLoading, data } = useGetWinnersQuery({
    search: state.search,
    skip: state.skip,
    take: state.take,
  });

  const columns = [
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Gift",
      selector: (row) => row.gift.title,
      sortable: true,
    },
  ];

  // Transform data

  const handleChangeSearch = useDebouncedCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, 1000);

  const tHead = useMemo(() => {
    return (
      <div className="filter-side">
        <input
          placeholder="For search name, lastname, id, email..."
          onChange={handleChangeSearch}
          name="search"
          // value={state.search}
        />
      </div>
    );
  }, [handleChangeSearch]);

  return (
    <>
      <Loader isLoading={isLoading} />

      <Accordion
        onSelect={() => {
          setState((prevState) => ({
            ...prevState,
            search: "",
          }));
        }}
      >
        {data &&
          data.map((dateRange) => (
            <Accordion.Item eventKey={dateRange.id}>
              <Accordion.Header>
                {dateRange.startDate} - {dateRange.endDate} {dateRange.type}
              </Accordion.Header>
              <Accordion.Body>
                <DataTable
                  // title="All Winners"
                  subHeader
                  subHeaderComponent={tHead}
                  progressPending={isLoading}
                  progressComponent={<Loader isLoading />}
                  columns={columns}
                  className="winners-table"
                  data={
                    state.search
                      ? dateRange.winners.filter(
                          (i) =>
                            i.points === parseInt(state.search) ||
                            i.firstName
                              .toLowerCase()
                              .includes(state.search.toLowerCase()) ||
                            i.lastName
                              .toLowerCase()
                              .includes(state.search.toLowerCase()) ||
                            i.email
                              .toLowerCase()
                              .includes(state.search.toLowerCase()) ||
                            i.phone.trim() === state.search.trim()
                        )
                      : dateRange.winners
                  }
                  //
                  pagination
                  paginationServer
                  paginationTotalRows={
                    state.skip +
                    state.take +
                    (dateRange &&
                    dateRange.winners &&
                    dateRange.winners.length < 20
                      ? 0
                      : 1)
                  }
                  paginationPerPage={20}
                  paginationComponentOptions={{
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    rowsPerPageText: "",
                  }}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        {console.log("!isLoading && data?.length < 1", isLoading, data)}
        {!isLoading && !data?.length ? (
          <h4 className="no-data">We have not collected data yet.</h4>
        ) : null}
      </Accordion>
    </>
  );
};
